import React from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";

const YesNoModal = (props) => {
  const { message, title, show, yesClick } = props;

  return (
    <Modal show={show}>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Row className="mb-4">
              <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-1 text-center">
                {title}
              </h1>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row className="mb-4">
              <span style={styles.message}>
                {message}
              </span>
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white mr-4"
          onClick={yesClick}
        >
          Yes
        </Button>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={props.toggleModal}
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { YesNoModal };

const styles = {
  message: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 22,
    color: "red"
  }
}

import React from "react";
import { Col, Row } from "react-bootstrap";

const SearchRequest = () => {
  return (
    <div className="p-4">
      <Row>
        <Col className="d-flex align-items-end">
          <h1 style={styles.pageTitle}>Search Request</h1>
        </Col>
      </Row>
    </div>
  );
};

export default SearchRequest;

const styles = {
  pageTitle: {
    textAlign: "left",
    color: "#0d6efd",
  },
};

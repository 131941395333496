import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { isBrowser } from "react-device-detect";
import { postCartCalc } from "../../../../../api/vgu/api";


const InquiryItemsModal = (props) => {
  const { show, inquiry, toggleModal } = props;
  const [cartCalc, setCartCalc] = useState({
    deposits: {
      totalGBP: 0,
      totalUSD: 0,
      tablesUSD: 0,
    },
    tableInfo: {
      totalUSD: 0,
    },
    grandTotal: 0,
    grandTotalGBP: 0,
    itemsCount: 0,
  });

  // Handlers
  const handleCancel = () => {
    resetPage();
  };

  // Methods
  const resetPage = (shouldUpdateResults = false) => {
    toggleModal(false);
  };

  // Sections
  const DataTable = () => {
    const table_rows = inquiry.items.map((item) => {
      return (
        <tr key={item.id}>
          <td className="px-6 py-3">{item.eventDate}</td>
          <td className="px-6 py-3">{item.type}</td>
          <td className="px-6 py-3">{item.venueName}</td>
          <td className="px-6 py-3">{item.eventName}</td>
          <td className="px-6 py-3">
            <NumericFormat
              value={item.price}
              displayType={"text"}
              thousandSeparator={true}
              prefix={item.currency === "USD" ? "$" : "£"}
              decimalScale={2}
              fixedDecimalScale={true}
              renderText={(formattedValue) => <span> {formattedValue}</span>}
            />
          </td>
          <td className="px-6 py-3">{item.quantity}</td>
        </tr>
      );
    });

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Venue Name</th>
            <th>Event Name</th>
            <th>Price</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>{table_rows}</tbody>
      </Table>
    );
  };

  const DataTableMobile = () => {
    const cards = inquiry.items.map((item) => {
      return (
        <Col key={item.id} xs={12} className="pb-3">
          <Card>
            <Card.Header>
              <Row>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#0d6efd",
                    textAlign: "center",
                  }}
                >
                  {item.eventName}
                </span>
              </Row>
            </Card.Header>
            <Card.Body style={{textAlign: "center"}}>
              <Row>
                <span style={{ color: "#0d6efd" }}>
                  {item.venueName}
                </span>
              </Row>
              <Row>
                <Col>
                  <span style={{ textAlign: "center" }}>{item.type}</span>
                </Col>
                <Col>
                  <span>{item.quantity} item(s)</span>
                </Col>
              </Row>
              <Row>
                <Col xs="auto">
                  <span>{item.eventDate}</span>
                </Col>
                <Col>
                  <NumericFormat
                    value={item.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={item.currency === "USD" ? "$" : "£"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={(formattedValue) => (
                      <span> {formattedValue}</span>
                    )}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      );
    });

    return <Row>{cards}</Row>;
  };

  // Hooks
  useEffect(() => {
    const items = inquiry.items.map((item) => {
      return {
        category: item.type,
        currency: item.currency,
        eventId: item.eventId,
        price: item.price,
        productId: item.productId,
        quantity: item.quantity,
        type: item.type,
      };
    });

    const jsonBody = {
      items: items,
    };

    if (show) {
      postCartCalc(jsonBody, (data) => {
        setCartCalc(data.data);
      });
    }
  }, [show, inquiry.items]);

  return (
    <Modal show={show} size="lg">
      <Modal.Body>
        <Form style={styles.form}>
          <Form.Group>
            <Row className="mb-4">
              <h1 style={styles.pageTitle}>Inquiry Items</h1>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row className="mb-3">
              <Col xs={12} md={5} className="mb-4 px-3">
                <Row style={styles.labels}>
                  Client Name: {inquiry.clientName}
                </Row>
                <Row>Trip Period: {inquiry.tripPeriod}</Row>
                <Row>
                  Party Size: {inquiry.femaleCount} females, {inquiry.maleCount}{" "}
                  males
                </Row>
                <Row>Items: {inquiry.items.length}</Row>
              </Col>
              <Col xs={5} md={3}>
                <Row style={styles.labels}>Deposits:</Row>
                <Row>Total GBP: {cartCalc.deposits.totalGBP}</Row>
                <Row>Total USD: {cartCalc.deposits.totalUSD}</Row>
                <Row>Tables USD: {cartCalc.deposits.tablesUSD}</Row>
              </Col>
              <Col xs={7} md="auto">
                <Row style={styles.labels}>Summary:</Row>
                <Row>Table(s) Total: {cartCalc.tableInfo.totalUSD}</Row>
                <Row>
                  <NumericFormat
                    value={cartCalc.grandTotalGBP}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"£"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={(formattedValue) => (
                      <>Grand Total GBP: {formattedValue}</>
                    )}
                  />
                </Row>
                <Row>
                  <NumericFormat
                    value={cartCalc.grandTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={(formattedValue) => (
                      <>Grand Total USD: {formattedValue}</>
                    )}
                  />
                </Row>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            {isBrowser ? <DataTable /> : <DataTableMobile />}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={(e) => handleCancel(e)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InquiryItemsModal;

const styles = {
  labels: {
    fontWeight: "bold",
  },
  pageTitle: {
    textAlign: "center",
    color: "#0d6efd",
  },
};

import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SiteRoutes } from "../../contants";
import { setDataActiveTitle } from "./sidebarSlice";
import { useAuth } from "../../providers/AuthProvider";

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const { activeTitle } = props;
  const { handleSignOut, user } = useAuth();

  // Sections
  const DataSubSection = (props) => {
    const data = [
      {
        groupId: 0,
        clientId: "cvds",
        clientName: "CVDS",
        routes: [],
      },
      {
        groupId: 1,
        clientId: "npp",
        clientName: "Net Process Plus",
        routes: [
          {
            id: 1,
            icon: "fa-solid fa-sack-dollar",
            title: SiteRoutes.npp.buyers.title,
            route: SiteRoutes.npp.buyers.path,
          },
          {
            id: 2,
            icon: "fa-solid fa-hammer",
            title: SiteRoutes.npp.contractors.title,
            route: SiteRoutes.npp.contractors.path,
          },
          {
            id: 3,
            icon: "fa-solid fa-building-columns",
            title: SiteRoutes.npp.professionalResources.title,
            route: SiteRoutes.npp.professionalResources.path,
          },
          {
            id: 4,
            icon: "fa-solid fa-house",
            title: SiteRoutes.npp.properties.title,
            route: SiteRoutes.npp.properties.path,
          },
          {
            id: 5,
            icon: "fa-solid fa-calculator",
            title: SiteRoutes.npp.rehabCalculator.title,
            route: SiteRoutes.npp.rehabCalculator.path,
          },
        ],
      },
      {
        groupId: 2,
        clientId: "vgu",
        clientName: "VIP Global UK",
        routes: [
          {
            id: 1,
            icon: "fa-solid fa-file-circle-question",
            title: SiteRoutes.vgu.inquiries.title,
            route: SiteRoutes.vgu.inquiries.path,
          },
          {
            id: 2,
            icon: "fa-solid fa-building",
            title: SiteRoutes.vgu.venues.title,
            route: SiteRoutes.vgu.venues.path,
          },
          {
            id: 3,
            icon: "fa-solid fa-calendar-days",
            title: SiteRoutes.vgu.venueEvents.title,
            route: SiteRoutes.vgu.venueEvents.path,
          },
          // {
          //   id: 4,
          //   icon: "fa-solid fa-chair",
          //   title: SiteRoutes.vgu.venueSeating.title,
          //   route: SiteRoutes.vgu.venueSeating.path,
          // },
        ],
      },
    ];

    const group = data.map((group, index) => {
      const items = group.routes.map((item) => {
        return (
          <MenuItem
            key={item.id}
            iconName={item.icon}
            text={item.title}
            route={item.route}
            isSubMenu={true}
          />
        );
      });

      return (
        <SidebarMenu.Sub key={group.groupId} eventKey={index}>
          <SidebarMenu.Sub.Toggle>
            <SidebarMenu.Nav.Icon />
            <SidebarMenu.Nav.Title style={styles.menuText}>
              <SidebarMenu.Text>{group.clientName}</SidebarMenu.Text>
            </SidebarMenu.Nav.Title>
          </SidebarMenu.Sub.Toggle>
          <SidebarMenu.Sub.Collapse>
            <SidebarMenu.Nav>{items}</SidebarMenu.Nav>
          </SidebarMenu.Sub.Collapse>
        </SidebarMenu.Sub>
      );
    });

    return group;
  };

  const MenuItem = (props) => {
    const menuIcon = props.iconName;
    const menuText = props.text;
    const route = props.route;

    const iconStyle =
      menuText === activeTitle ? styles.iconActive : styles.icon;
    const menuTextStyle =
      menuText === activeTitle ? styles.menuTextActive : styles.menuText;

    return (
      <SidebarMenu.Nav.Link
        className="d-flex justify-content-start align-items-center"
        eventKey={menuText}
        href={route}
        style={styles.menuLink}
      >
        <SidebarMenu.Nav.Icon>
          <FontAwesomeIcon icon={menuIcon} size="1x" style={iconStyle} />
        </SidebarMenu.Nav.Icon>
        <SidebarMenu.Nav.Title>
          <SidebarMenu.Text style={menuTextStyle}>{menuText}</SidebarMenu.Text>
        </SidebarMenu.Nav.Title>
      </SidebarMenu.Nav.Link>
    );
  };

  const UserSubSection = () => {
    return (
      <Col>
        <Row className="mb-3">
          <Col className="d-flex justify-content-center">
            <SidebarMenu.Text style={styles.userInfoText}>
              {user.displayName}
            </SidebarMenu.Text>
          </Col>
        </Row>
        <Row>
          <Col className="px-4">
            <SidebarMenu.Nav.Link
              className="d-flex justify-content-start align-items-center"
              eventKey={"logout"}
              role="button"
              onSelect={handleSignOut}
              style={styles.menuLink}
            >
              <SidebarMenu.Nav.Icon>
                <FontAwesomeIcon
                  icon={"fa-solid fa-right-to-bracket"}
                  size="2x"
                  style={styles.menuIcon}
                />
              </SidebarMenu.Nav.Icon>
              <SidebarMenu.Nav.Title>
                <SidebarMenu.Text style={styles.menuText}>
                  Logout
                </SidebarMenu.Text>
              </SidebarMenu.Nav.Title>
            </SidebarMenu.Nav.Link>
          </Col>
        </Row>
      </Col>
    );
  };

  const dataSubSection = useMemo(() => {
    return <DataSubSection />;
  }, []);

  return (
    <SidebarMenu
      bg="dark"
      variant="dark"
      hide="md"
      expand="lg"
      activeKey={activeTitle}
      style={styles.menu}
      onSelect={(title) => dispatch(setDataActiveTitle(title))}
    >
      <SidebarMenu.Collapse>
        <div style={styles.stack}>
          <div>
            <SidebarMenu.Header>
              <SidebarMenu.Brand style={{ paddingLeft: 10 }}>
                CVDS
              </SidebarMenu.Brand>
              <SidebarMenu.Toggle />
            </SidebarMenu.Header>
            <SidebarMenu.Body>
              <SidebarMenu.Nav>
                <MenuItem
                  iconName="fa-solid fa-gauge"
                  text={SiteRoutes.dashboard.title}
                  route={SiteRoutes.dashboard.path}
                />
                <MenuItem
                  iconName="fa-solid fa-file-circle-plus"
                  text={SiteRoutes.serviceRequest.title}
                  route={SiteRoutes.serviceRequest.path}
                />
                {dataSubSection}
                {/* <DataSubSection /> */}
              </SidebarMenu.Nav>
            </SidebarMenu.Body>
          </div>
          <div style={styles.footerContainer}>
            <SidebarMenu.Footer>
              <UserSubSection />
            </SidebarMenu.Footer>
          </div>
        </div>
      </SidebarMenu.Collapse>
    </SidebarMenu>
  );
};

export default Sidebar;

const styles = {
  footerContainer: {
    padding: 10,
    minHeight: 100,
  },
  icon: {
    color: "#FFF",
  },
  iconActive: {
    color: "yellow",
  },
  menu: {
    height: "100vh",
    flexDirection: "column",
    overflowX: "hidden",
  },
  menuLink: {
    textDecoration: "none",
    fontWeight: "bold",
  },
  menuText: {
    color: "#FFF",
  },
  menuTextActive: {
    color: "yellow",
  },
  stack: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  userContainer: {},
  userInfoText: {
    color: "#FFF",
    fontWeight: "bold",
  },
};

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NumericFormat } from "react-number-format";
import { isBrowser } from "react-device-detect";
import { toast } from "react-toastify";
import AddEditVenueEventModal from "./AddEditVenueEventModal";
import AddEditVenueSeatingModal from "../VenueSeating/AddEditVenueSeatingModal";
import DuplicateVenueSeatingModal from "../VenueSeating/DuplicateVenueSeatingModal";
import FilterModal from "../../../../../components/modals/FilterModal";
import { YesNoModal } from "../../../../../components/modals/Modals";
import {
  deleteVenueEvent,
  deleteVenueSeating,
  getFilterInfo,
  getVenueEvents,
  postEditVenueSeating,
} from "../../../../../api/ApiManager";
import { dateTime } from "../../../../../lib/utils";

const VenueEvents = () => {
  const defaultState = {
    editMode: false,
    event: {
      id: 0,
      name: "",
      date: new Date(),
      genre: "",
    },
    eventId: 0,
    eventStartDate: new Date(),
    eventEndDate: new Date(),
    locationId: 0,
    locations: [
      {
        id: 0,
        name: "",
        venues: [],
      },
    ],
    tableEditId: -1,
    subTableEditId: -1,
    venueId: 0,
    viewDataButtonIsDisable: true,
    venueTable: {
      id: 0,
      venueId: 0,
      venueEventId: 0,
      name: "",
      minPrice: 0,
      maxGuestFemales: 0,
      maxGuestMales: 0,
      tableDescription: "",
      seatingCategory: "",
    },
  };

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showAddVenueSeatingModal, setShowAddVenueSeatingModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteModalVenueSeating, setShowDeleteModalVenueSeating] =
    useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [eventsData, setEventsData] = useState({
    events: [],
  });
  const [venueEventsState, setVenueEventsState] = useState(defaultState);

  // Filter Select Data
  const locationSelectData =
    venueEventsState.locations &&
    venueEventsState.locations.map((location) => {
      return {
        id: location.id,
        name: location.name,
        value: location.id,
      };
    });

  const venueSelectData = () => {
    if (venueEventsState.locations === null) {
      return [];
    }

    const location = venueEventsState.locations.find((location) => {
      return location.id === venueEventsState.locationId;
    });

    let venues = [];

    if (location !== undefined && location.venues.length > 0) {
      venues = location.venues.map((venue) => {
        return {
          id: venue.id,
          name: venue.name,
          value: venue.id,
        };
      });
    }

    return venues;
  };

  // Handlers
  const handleAddEvent = (e) => {
    e.stopPropagation();
    setVenueEventsState((prev) => ({
      ...prev,
      editMode: false,
      event: {
        name: "",
        genre: "",
        date: new Date(),
      },
    }));
    setShowAddEditModal(true);
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "DatePickerEndDate": {
        const startDate = moment(venueEventsState.eventStartDate).toDate();
        const endDate = moment(e.target.value).toDate();

        if (startDate > endDate) {
          setVenueEventsState((prev) => ({
            ...prev,
            eventStartDate: e.target.value,
            eventEndDate: e.target.value,
          }));
        } else {
          setVenueEventsState((prev) => ({
            ...prev,
            eventEndDate: e.target.value,
          }));
        }

        break;
      }
      case "DatePickerStartDate": {
        const startDate = moment(e.target.value).toDate();
        const endDate = moment(venueEventsState.eventEndDate).toDate();

        if (startDate > endDate) {
          setVenueEventsState((prev) => ({
            ...prev,
            eventStartDate: e.target.value,
            eventEndDate: e.target.value,
          }));
        } else {
          setVenueEventsState((prev) => ({
            ...venueEventsState,
            eventStartDate: e.target.value,
          }));
        }
        break;
      }
      case "SelectMenu1": // Location
        setVenueEventsState((prev) => ({
          ...venueEventsState,
          locationId: parseInt(e.target.value),
        }));
        break;
      case "SelectMenu2":
        setVenueEventsState((prev) => ({
          ...prev,
          venueId: parseInt(e.target.value),
          viewDataButtonIsDisable: false,
        }));
        break;
      default:
        break;
    }
  };

  const handleChangeSubTable = (e) => {
    switch (e.target.name) {
      default:
        setVenueEventsState((prev) => ({
          ...prev,
          venueTable: {
            ...prev.venueTable,
            [e.target.name]: e.target.value,
          },
        }));
        break;
    }
  };

  const handleDelete = () => {
    const params = {
      venueEventId: venueEventsState.event.id,
    };

    deleteVenueEvent(params, () => {
      toggleDeleteModal(true);
      toast.error("Venue Event Deleted!");
    });
  };

  const handleDeleteVenueSeating = () => {
    const params = {
      venueSeatingId: venueEventsState.venueTable.id,
    };

    deleteVenueSeating(params, () => {
      toggleDeleteModalVenueSeating(venueEventsState.venueTable.id);
      toast.error("Venue Table Deleted!");
    });
  };

  const handleDuplicateEvent = () => {
    setShowDuplicateModal(true);
  };

  const handleRowExpanded = (id) => {
    const updatedEvents = eventsData.events.map((event) => {
      if (event.id === id) {
        return { ...event, expanded: !event.expanded };
      }
      return event;
    });

    setEventsData({ ...eventsData, events: updatedEvents });
  };

  const handleSaveVenueSeating = (e) => {
    e.stopPropagation();

    const dataBody = {
      venueseatingid: venueEventsState.venueTable.id,
      venueid: venueEventsState.venueId,
      venueeventid: venueEventsState.event.id,
      tablename: venueEventsState.venueTable.name,
      minprice: venueEventsState.venueTable.minPrice,
      maxguestfemales: parseInt(venueEventsState.venueTable.maxGuestFemales),
      maxguestmales: parseInt(venueEventsState.venueTable.maxGuestMales),
      description: venueEventsState.venueTable.tableDescription,
    };

    postEditVenueSeating(dataBody, () => {
      toast.error("Table Updated!");

      setVenueEventsState((prev) => ({
        ...prev,
        subTableEditId: -1,
      }));

      updateVenueSeatingData(venueEventsState.venueTable.id);
    });
  };

  const handleViewResults = () => {
    const params = {
      venueId: venueEventsState.venueId,
      startDate: moment(venueEventsState.eventStartDate).format("MM/DD/YYYY"),
      endDate: moment(venueEventsState.eventEndDate).format("MM/DD/YYYY"),
    };

    getVenueEvents(params, (data) => {
      const events = data.map((event) => {
        return {
          ...{ expanded: false },
          ...event,
        };
      });

      setEventsData({
        ...{ events: events },
        ...data,
      });
    });
  };

  // Methods
  const getVenue = (venueId) => {
    if (venueEventsState.locationId === 0) return null;

    const location = venueEventsState.locations.find((location) => {
      return location.id === venueEventsState.locationId;
    });

    const venue = location.venues.find((venue) => {
      return venue.id === venueId;
    });

    return venue;
  };

  // CRUD Records
  const addVenueSeatingData = (table) => {
    setVenueEventsState((prev) => {
      const state = { ...prev };
      state.event.tables.push(table);
      return state;
    });
  };

  const deleteVenueSeatingData = (tableId) => {
    const index = venueEventsState.event.tables.findIndex(
      (table) => table.id === tableId
    );

    if (index > -1) {
      setVenueEventsState((prev) => {
        const state = { ...prev };
        let tables = state.event.tables;
        tables.splice(index, 1);
        state.event.tables = tables;
        return state;
      });
    }
  };

  const updateVenueSeatingData = (tableId) => {
    const index = venueEventsState.event.tables.findIndex(
      (table) => table.id === tableId
    );

    if (index > -1) {
      setVenueEventsState((prev) => {
        const state = { ...prev };
        state.event.tables[index] = venueEventsState.venueTable;
        state.subTableEditId = -1;
        return state;
      });
    }
  };

  // Modals
  const toggleAddEditModal = (shouldUpdateResults = false) => {
    setShowAddEditModal(!showAddEditModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  const toggleAddVenueSeatingModal = (table = null) => {
    setShowAddVenueSeatingModal(!showAddVenueSeatingModal);

    if (table !== null) {
      addVenueSeatingData(table);
    }
  };

  const toggleDeleteModal = (shouldUpdateResults = false) => {
    setShowDeleteModal(!showDeleteModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  const toggleDeleteModalVenueSeating = (tableId) => {
    setShowDeleteModalVenueSeating(!showDeleteModalVenueSeating);

    deleteVenueSeatingData(tableId);
  };

  const toggleDuplicateModal = (shouldUpdateResults = false) => {
    setShowDuplicateModal(!showDuplicateModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  const toggleFilterModal = (shouldUpdateResults) => {
    setShowFilterModal(!showFilterModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  const DataTableMobile = () => {
    const cards = eventsData.events.map((event) => {
      return (
        <Col key={event.id} md={4} className="pb-3">
          <Card>
            <Card.Header>
              <Row>
                <span style={{ fontWeight: "bold", color: "#0d6efd" }}>
                  {event.name}
                </span>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Col>
                  <Image
                    src={
                      event.imageUrl !== ""
                        ? event.imageUrl
                        : require("../../../../../assets/images/club.png")
                    }
                    height="140"
                    width="140"
                    alt={event.name}
                  />
                </Col>
              </Row>
              <Row>
                <p>{dateTime(event.date)}</p>
              </Row>
              <Row>
                <p>{event.genre}</p>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setVenueEventsState((prev) => ({
                        ...prev,
                        editMode: true,
                        event: event,
                      }));
                      setShowAddEditModal(true);
                    }}
                    aria-controls="add-edit-venue-seating-modal"
                  >
                    <span style={styles.button}>Edit</span>
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setVenueEventsState((prev) => ({
                        ...prev,
                        editMode: false,
                        event: event,
                      }));
                      setShowDeleteModal(true);
                    }}
                    style={styles.deleteButton}
                  >
                    <span style={styles.button}>Delete</span>
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      );
    });

    return <Col>{cards}</Col>;
  };

  // Hooks
  useEffect(() => {
    getFilterInfo((info) => {
      setVenueEventsState((prev) => ({
        ...prev,
        locations: info.locations,
      }));
    });
  }, []);

  return (
    <Container fluid>
      <Col className="p-0">
        <Row className="mb-3">
          <Col className="d-flex align-items-end">
            <h1 style={styles.pageTitle}>Venue Events</h1>
          </Col>
          <Col className="px-4 py-2" xs="auto" style={styles.filterSection}>
            <Row>
              <Col className="d-flex justify-content-between">
                <Button
                  style={styles.clearButtonStyle}
                  onClick={handleAddEvent}
                >
                  <FontAwesomeIcon
                    icon={"fa-solid fa-square-plus"}
                    size="2x"
                    style={styles.icon}
                  />
                </Button>
                <Button
                  style={styles.clearButtonStyle}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFilterModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    icon={"fa-solid fa-filter"}
                    size="2x"
                    style={styles.icon}
                  />
                </Button>
              </Col>
            </Row>
            <Row>
              <span style={{ textAlign: "right", fontWeight: "bold" }}>
                {eventsData.events.length} events
              </span>
            </Row>
          </Col>
        </Row>
        <Row style={styles.table}>
          {eventsData.events.length === 0 ? (
            <Row>
              <Col>
                <p style={{ fontSize: 22, fontWeight: "bold", color: "red" }}>
                  No Data: Change Filter
                </p>
              </Col>
            </Row>
          ) : (
            <React.Fragment>
              {isBrowser ? (
                // Browser Version
                <Col>
                  <Table borderless hover>
                    <thead style={styles.tableHeader}>
                      <tr>
                        <th></th>
                        <th>Event Image</th>
                        <th>Event Name</th>
                        <th>Date</th>
                        <th>Genre</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventsData.events.map((event, index) => {
                        return (
                          <React.Fragment key={`${event.id}-${index}`}>
                            <tr style={{ verticalAlign: "middle" }}>
                              <td>
                                <Button
                                  style={styles.clearButtonStyle}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRowExpanded(event.id);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      event.expanded
                                        ? "fa-solid fa-caret-down"
                                        : "fa-solid fa-caret-right"
                                    }
                                    size="2x"
                                    style={styles.icon}
                                  />
                                </Button>
                              </td>
                              <td className="px-6 py-3">
                                <img
                                  src={
                                    event.imageUrl !== ""
                                      ? event.imageUrl
                                      : require("../../../../../assets/images/club.png")
                                  }
                                  style={styles.eventImage}
                                  alt={event.name}
                                />
                              </td>
                              <td className="px-6 py-3">{event.name}</td>
                              <td className="px-6 py-3">
                                {dateTime(event.date)}
                              </td>
                              <td className="px-6 py-3">{event.genre}</td>
                              <td style={styles.buttonColumn}>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setVenueEventsState((prev) => ({
                                      ...prev,
                                      editMode: true,
                                      event: event,
                                    }));
                                    setShowAddEditModal(true);
                                  }}
                                  aria-controls="add-edit-venue-seating-modal"
                                >
                                  <span style={styles.button}>Edit</span>
                                </Button>
                              </td>
                              <td style={styles.buttonColumn}>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setVenueEventsState((prev) => ({
                                      ...prev,
                                      editMode: false,
                                      event: event,
                                    }));
                                    setShowDeleteModal(true);
                                  }}
                                  style={styles.deleteButton}
                                >
                                  <span style={styles.button}>Delete</span>
                                </Button>
                              </td>
                            </tr>
                            {event.expanded && (
                              <tr style={styles.subTable}>
                                <td colSpan="7">
                                  <Table
                                    borderless
                                    hover
                                    style={{
                                      textAlign: "left",
                                      paddingBottom: 0,
                                    }}
                                  >
                                    <thead style={styles.tableHeader}>
                                      <tr>
                                        <th>Table Name</th>
                                        <th>Min. Price</th>
                                        <th>Max Guest Females</th>
                                        <th>Max Guest Males</th>
                                        <th>Description</th>
                                        <th></th>
                                        <th
                                          style={{
                                            textAlign: "right",
                                          }}
                                        >
                                          <Col className="d-flex justify-content-between align-items-end">
                                          <Button
                                            style={styles.clearButtonStyle}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setVenueEventsState((prev) => ({
                                                ...prev,
                                                event: event,
                                              }));
                                              setShowAddVenueSeatingModal(true);
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={"fa-solid fa-square-plus"}
                                              size="2x"
                                              style={styles.icon}
                                            />
                                          </Button>
                                          <Button
                                            style={styles.clearButtonStyle}
                                            onClick={handleDuplicateEvent}
                                          >
                                            <FontAwesomeIcon
                                              icon={"fa-solid fa-clone"}
                                              size="2x"
                                              style={styles.icon}
                                            />
                                          </Button>
                                          </Col>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {event.tables.map((table, index) => {
                                        return venueEventsState.subTableEditId ===
                                          table.id ? (
                                          <tr
                                            key={`select-${table.id}-${index}`}
                                            style={{ backgroundColor: "white" }}
                                          >
                                            <td>
                                              <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="name"
                                                name="name"
                                                type="text"
                                                placeholder="Table Name"
                                                defaultValue={
                                                  venueEventsState.venueTable
                                                    .name
                                                }
                                                onChange={handleChangeSubTable}
                                                style={styles.textInput}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="minPrice"
                                                name="minPrice"
                                                type="text"
                                                placeholder={0}
                                                defaultValue={
                                                  venueEventsState.venueTable
                                                    .minPrice
                                                }
                                                onChange={handleChangeSubTable}
                                                style={styles.textInput}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="maxGuestFemales"
                                                name="maxGuestFemales"
                                                type="text"
                                                placeholder={0}
                                                defaultValue={
                                                  venueEventsState.venueTable
                                                    .maxGuestFemales
                                                }
                                                onChange={handleChangeSubTable}
                                                style={styles.textInput}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="maxGuestMales"
                                                name="maxGuestMales"
                                                type="text"
                                                placeholder={0}
                                                defaultValue={
                                                  venueEventsState.venueTable
                                                    .maxGuestMales
                                                }
                                                onChange={handleChangeSubTable}
                                                style={styles.textInput}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="tableDescription"
                                                name="tableDescription"
                                                type="text"
                                                placeholder={""}
                                                defaultValue={
                                                  venueEventsState.venueTable
                                                    .tableDescription
                                                }
                                                onChange={handleChangeSubTable}
                                                style={styles.textInput}
                                              />
                                            </td>
                                            <td style={styles.buttonColumn}>
                                              <Button
                                                onClick={handleSaveVenueSeating}
                                                style={styles.updateButton}
                                              >
                                                <span style={styles.button}>
                                                  Update
                                                </span>
                                              </Button>
                                            </td>
                                            <td style={styles.buttonColumn}>
                                              <Button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setVenueEventsState(
                                                    (prev) => ({
                                                      ...prev,
                                                      subTableEditId: -1,
                                                    })
                                                  );
                                                }}
                                              >
                                                <span style={styles.button}>
                                                  Cancel
                                                </span>
                                              </Button>
                                            </td>
                                          </tr>
                                        ) : (
                                          <tr
                                            key={`select-${table.id}-${index}`}
                                            style={{ backgroundColor: "white" }}
                                          >
                                            <td className="px-2">
                                              {table.name}
                                            </td>
                                            <td className="px-2">
                                              <NumericFormat
                                                value={table.minPrice}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                renderText={(
                                                  formattedValue
                                                ) => (
                                                  <span> {formattedValue}</span>
                                                )}
                                              />
                                            </td>
                                            <td className="px-2">
                                              {table.maxGuestFemales}
                                            </td>
                                            <td className="px-2">
                                              {table.maxGuestMales}
                                            </td>
                                            <td
                                              className="px-2"
                                              style={{ textAlign: "left" }}
                                            >
                                              {table.tableDescription}
                                            </td>
                                            <td style={styles.buttonColumn}>
                                              <Button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setVenueEventsState(
                                                    (prev) => ({
                                                      ...prev,
                                                      subTableEditId: table.id,
                                                      event: event,
                                                      venueTable: table,
                                                    })
                                                  );
                                                }}
                                                aria-controls="add-edit-venue-seating-modal"
                                              >
                                                <span style={styles.button}>
                                                  Edit
                                                </span>
                                              </Button>
                                            </td>
                                            <td style={styles.buttonColumn}>
                                              <Button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setVenueEventsState(
                                                    (prev) => ({
                                                      ...prev,
                                                      event: event,
                                                      venueTable: table,
                                                    })
                                                  );
                                                  setShowDeleteModalVenueSeating(
                                                    true
                                                  );
                                                }}
                                                style={styles.deleteButton}
                                              >
                                                <span style={styles.button}>
                                                  Delete
                                                </span>
                                              </Button>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              ) : (
                // Mobile Version
                <Col></Col>
              )}
            </React.Fragment>
          )}
        </Row>
        {showAddEditModal && (
          <AddEditVenueEventModal
            id="add-edit-venue-event-modal"
            show={showAddEditModal}
            toggleModal={toggleAddEditModal}
            event={venueEventsState.event}
            locations={venueEventsState.locations}
            editMode={venueEventsState.editMode}
          />
        )}
        {showAddVenueSeatingModal && (
          <AddEditVenueSeatingModal
            id="add-venue-seating-modal"
            show={showAddVenueSeatingModal}
            toggleModal={toggleAddVenueSeatingModal}
            event={venueEventsState.event}
            venue={getVenue(venueEventsState.venueId)}
          />
        )}
        {showDuplicateModal && (
          <DuplicateVenueSeatingModal
            id="duplicate-venue-seating-modal"
            show={showDuplicateModal}
            toggleModal={toggleDuplicateModal}
            table={venueEventsState.venueTable}
            locations={venueEventsState.locations}
          />
        )}
        {showFilterModal && (
          <FilterModal
            id="inquiry-filter-modal"
            show={showFilterModal}
            toggleModal={toggleFilterModal}
            startDateDataOnChange={handleChange}
            startDateValue={venueEventsState.eventStartDate}
            endDateDataOnChange={handleChange}
            endDateValue={venueEventsState.eventEndDate}
            selectMenu1Title="Location"
            selectMenu1Data={locationSelectData}
            selectMenu1DataOnChange={handleChange}
            selectMenu1Value={venueEventsState.locationId}
            selectMenu2Title="Venue"
            selectMenu2Data={venueSelectData()}
            selectMenu2DataOnChange={handleChange}
            selectMenu2Value={venueEventsState.venueId}
          />
        )}
        {showDeleteModal && (
          <YesNoModal
            show={showDeleteModal}
            toggleModal={toggleDeleteModal}
            title="Delete"
            message={`Are you sure you want to delete ${venueEventsState.event.name}: ${venueEventsState.event.date}?`}
            yesClick={handleDelete}
          />
        )}
        {showDeleteModalVenueSeating && (
          <YesNoModal
            show={showDeleteModalVenueSeating}
            toggleModal={toggleDeleteModalVenueSeating}
            title="Delete"
            message={`Are you sure you want to delete ${venueEventsState.venueTable.name}: $${venueEventsState.venueTable.minPrice}?`}
            yesClick={handleDeleteVenueSeating}
          />
        )}
      </Col>
    </Container>
  );
};

export default VenueEvents;

const styles = {
  button: {
    color: "white",
    fontWeight: "bold",
  },
  buttonColumn: {
    textAlign: "center",
    width: 100,
  },
  clearButtonStyle: {
    color: "#0d6efd",
    background: "none",
    border: "none",
    outline: "none",
    padding: 0,
  },
  deleteButton: {
    backgroundColor: "red",
    borderColor: "red",
  },
  eventImage: {
    resizeMode: "contain",
    width: 80,
    height: 80,
  },
  filterSection: {
    minWidth: 120,
  },
  pageTitle: {
    textAlign: "left",
    color: "#0d6efd",
  },
  pickerTitle: {
    fontWeight: "bold",
    textAlign: "left",
  },
  subTable: {
    backgroundColor: "#0d6efd",
  },
  table: {
    marginTop: 30,
    height: "75vh",
    overflow: "scroll",
  },
  tableHeader: {
    backgroundColor: "#353A40",
    color: "white",
  },
  textInput: {
    width: "100%",
  },
  updateButton: {
    backgroundColor: "green",
    borderColor: "green",
  },
};

import { Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MARKER_DIAMETER = 40;
const MARKER_DIAMETER_HOVER = 60;

const CurrentLocationMarker = (props) => {
  return (
    <FontAwesomeIcon
      icon="fa-solid fa-location-crosshairs"
      size="2x"
      color="blue"
    />
  );
};

const Marker = (props) => {
  return (
    <div>
      <div style={styles.marker}>
        <FontAwesomeIcon
          icon="fa-solid fa-location-pin"
          size="3x"
          color={props.$hover ? "red" : "blue"}
        />
      </div>
      {props.$hover && (
        <Container className="bubble bottom" style={styles.bubble}>
          <Row>
            <span style={styles.bubbleTitle}>{props.data.title}</span>
          </Row>
          <Row>
            <span style={styles.bubbleLines}>{props.data.line2}</span>
          </Row>
          <Row>
            <span style={styles.bubbleLines}>{props.data.line3}</span>
          </Row>
        </Container>
      )}
    </div>
  );
};

export { MARKER_DIAMETER, CurrentLocationMarker, Marker };

const styles = {
  bubble: {
    borderRadius: 20,
    backgroundColor: "white",
    minWidth: 200,
    height: 110,
    position: "absolute",
    top: -195,
    left: -103,
    padding: 20,
    zIndex: 2,
  },
  bubbleTitle: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "left",
    padding: 0,
  },
  bubbleLines: {
    fontSize: 12,
    textAlign: "left",
    padding: 0,
  },
  currentLocationMarker: {
    position: "absolute",
    width: MARKER_DIAMETER,
    height: MARKER_DIAMETER,
    left: -MARKER_DIAMETER / 2,
    top: -MARKER_DIAMETER / 2,

    border: "2px solid blue",
    borderRadius: MARKER_DIAMETER,
    backgroundColor: "lightBlue",
    textAlign: "center",
    color: "red",
    fontSize: 16,
    fontWeight: "bold",
    padding: 4,
    opacity: 0.8,
  },
  marker: {
    position: "absolute",
    width: MARKER_DIAMETER,
    height: MARKER_DIAMETER,
    left: -MARKER_DIAMETER / 2,
    top: -MARKER_DIAMETER / 2,
    // border: "5px solid blue",
    opacity: 0.8,
    cursor: "pointer",
  },
  markerHover: {
    position: "absolute",
    width: MARKER_DIAMETER_HOVER,
    height: MARKER_DIAMETER_HOVER,
    left: -MARKER_DIAMETER_HOVER / 2,
    top: -MARKER_DIAMETER_HOVER / 2,
    // border: "5px solid red",
    opacity: 0.8,
    cursor: "pointer",
  },
};

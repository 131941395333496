import React, { useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postAddBuyerAreaInfo } from "../../../../../api/ApiManager";

const locations = [
  {
    id: 1,
    name: "Alabama",
    country: "USA",
  },
  {
    id: 2,
    name: "Alaska",
    country: "USA",
  },
  {
    id: 3,
    name: "Arizona",
    country: "USA",
  },
  {
    id: 4,
    name: "Arkansas",
    country: "USA",
  },
  {
    id: 5,
    name: "California",
    country: "USA",
  },
  {
    id: 6,
    name: "Colorado",
    country: "USA",
  },
  {
    id: 7,
    name: "Connecticut",
    country: "USA",
  },
  {
    id: 8,
    name: "Delaware",
    country: "USA",
  },
  {
    id: 9,
    name: "Florida",
    country: "USA",
  },
  {
    id: 10,
    name: "Georgia",
    country: "USA",
  },
  {
    id: 11,
    name: "Hawaii",
    country: "USA",
  },
  {
    id: 12,
    name: "Idaho",
    country: "USA",
  },
  {
    id: 13,
    name: "Illinois",
    country: "USA",
  },
  {
    id: 14,
    name: "Indiana",
    country: "USA",
  },
  {
    id: 15,
    name: "Iowa",
    country: "USA",
  },
  {
    id: 16,
    name: "Kansas",
    country: "USA",
  },
  {
    id: 17,
    name: "Kentucky",
    country: "USA",
  },
  {
    id: 18,
    name: "Louisiana",
    country: "USA",
  },
  {
    id: 19,
    name: "Maine",
    country: "USA",
  },
  {
    id: 20,
    name: "Maryland",
    country: "USA",
  },
  {
    id: 21,
    name: "Massachusetts",
    country: "USA",
  },
  {
    id: 22,
    name: "Michigan",
    country: "USA",
  },
  {
    id: 23,
    name: "Minnesota",
    country: "USA",
  },
  {
    id: 24,
    name: "Mississippi",
    country: "USA",
  },
  {
    id: 25,
    name: "Missouri",
    country: "USA",
  },
  {
    id: 26,
    name: "Montana",
    country: "USA",
  },
  {
    id: 27,
    name: "Nebraska",
    country: "USA",
  },
  {
    id: 28,
    name: "Nevada",
    country: "USA",
  },
  {
    id: 29,
    name: "New Hampshire",
    country: "USA",
  },
  {
    id: 30,
    name: "New Jersey",
    country: "USA",
  },
  {
    id: 31,
    name: "New Mexico",
    country: "USA",
  },
  {
    id: 32,
    name: "New York",
    country: "USA",
  },
  {
    id: 33,
    name: "North Carolina",
    country: "USA",
  },
  {
    id: 34,
    name: "North Dakota",
    country: "USA",
  },
  {
    id: 35,
    name: "Ohio",
    country: "USA",
  },
  {
    id: 36,
    name: "Oklahoma",
    country: "USA",
  },
  {
    id: 37,
    name: "Oregon",
    country: "USA",
  },
  {
    id: 38,
    name: "Pennsylvania",
    country: "USA",
  },
  {
    id: 39,
    name: "Rhode Island",
    country: "USA",
  },
  {
    id: 40,
    name: "South Carolina",
    country: "USA",
  },
  {
    id: 41,
    name: "South Dakota",
    country: "USA",
  },
  {
    id: 42,
    name: "Tennessee",
    country: "USA",
  },
  {
    id: 43,
    name: "Texas",
    country: "USA",
  },
  {
    id: 44,
    name: "Utah",
    country: "USA",
  },
  {
    id: 45,
    name: "Vermont",
    country: "USA",
  },
  {
    id: 46,
    name: "Virginia",
    country: "USA",
  },
  {
    id: 47,
    name: "Washington",
    country: "USA",
  },
  {
    id: 48,
    name: "West Virginia",
    country: "USA",
  },
  {
    id: 49,
    name: "Wisconsin",
    country: "USA",
  },
  {
    id: 50,
    name: "Wyoming",
    country: "USA",
  },
  {
    id: 51,
    name: "Washington D.C.",
    country: "USA",
  },
];

const BuyersAreaInfoModal = (props) => {
  const { buyerId, show, toggleModal } = props;
  const defaultState = {
    buyerId: 0,
    locationId: 0,
    locationInfo: "",
    criteria: "",
    notes: "",
  };
  const [saveButtonIsDisable, setSaveButtonIsDisable] = useState(true);
  const [addState, setAddState] = useState(defaultState);

  // Handlers
  const handleChange = (e) => {
    switch (e.target.name) {
      case "LocationSelectMenu":
        setAddState((prev) => ({
          ...prev,
          buyerId: buyerId,
          locationId: parseInt(e.target.value),
        }));
        setSaveButtonIsDisable(false);
        break;
      default:
        setAddState((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
    }
  };

  const handleCancel = () => {
    resetPage();
  };

  const handleSave = (e) => {
    e.stopPropagation();

    const dataBody = {
      buyerid: addState.buyerId,
      locationid: addState.locationId,
      locationinfo: addState.locationInfo,
      criteria: addState.criteria,
      notes: addState.notes,
    };

    postAddBuyerAreaInfo(dataBody, () => {
      resetPage(true);
      toast.error("Area Info Added!");
    });
  };

  // Methods
  const resetPage = (shouldUpdateResults = false) => {
    setAddState(defaultState);
    setSaveButtonIsDisable(true);
    toggleModal(shouldUpdateResults);
  };

  return (
    <Modal show={show} size="lg">
      <Modal.Body>
        <Form style={styles.form}>
          <Form.Group>
            <Row className="mb-4">
              <h1 style={styles.pageTitle}>Add Buyer's Area Info</h1>
            </Row>
          </Form.Group>
        </Form>
        <Form.Group>
          <Container>
            <Row className="mb-4">
              <Col md="auto" style={styles.labelCol}>
                <Form.Label>Location</Form.Label>
              </Col>
              <Col>
                <select
                  className="mb-2 p-2 w-100"
                  onChange={handleChange}
                  value={addState.locationId}
                  name="LocationSelectMenu"
                  id="LocationSelectMenu"
                >
                  <option value="default">Select a Location</option>
                  {locations.map((location) => {
                    return (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    );
                  })}
                </select>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md="auto" style={styles.labelCol}>
                <Form.Label>Location Info</Form.Label>
              </Col>
              <Col>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="locationInfo"
                  name="locationInfo"
                  type="text"
                  placeholder="Location Info"
                  onChange={handleChange}
                  style={styles.textInput}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md="auto" style={styles.labelCol}>
                <Form.Label>Criteria</Form.Label>
              </Col>
              <Col>
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="criteria"
                  name="criteria"
                  type="textarea"
                  placeholder="Criteria"
                  rows="5"
                  // defaultValue={table.tableDescription}
                  onChange={handleChange}
                  style={styles.textArea}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md="auto" style={styles.labelCol}>
                <Form.Label>Notes</Form.Label>
              </Col>
              <Col>
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="notes"
                  name="notes"
                  type="textarea"
                  placeholder="Notes"
                  rows="5"
                  // defaultValue={table.tableDescription}
                  onChange={handleChange}
                  style={styles.textArea}
                />
              </Col>
            </Row>
          </Container>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white mr-4"
          onClick={(e) => handleSave(e)}
          disabled={saveButtonIsDisable}
        >
          Add
        </Button>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={(e) => handleCancel(e)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BuyersAreaInfoModal;

const styles = {
  labelCol: {
    width: 120,
  },
  pageTitle: {
    textAlign: "center",
    color: "#0d6efd",
  },
  textInput: {
    width: "100%",
  },
  textArea: {
    width: "100%",
    resize: "none",
  },
};

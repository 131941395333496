import React, { useRef, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import moment from "moment";
import DatePicker from "../DatePicker";

const FilterModal = (props) => {
  const startDateRef = useRef();
  const endDateRef = useRef();

  const defaultState = {
    startDate: props.startDateValue ?? new Date(),
    endDate: props.endDateValue ?? new Date(),
    selectMenu1Value: props.selectMenu1Value ?? "",
    selectMenu2Value: props.selectMenu2Value ?? "",
    applyButtonIsDisable: true,
  };

  const {
    show,
    reset,
    toggleModal,
    startDateDataOnChange,
    endDateDataOnChange,
    selectMenu1Title,
    selectMenu1Data,
    selectMenu1DataOnChange,
    selectMenu2Title,
    selectMenu2Data,
    selectMenu2DataOnChange,
    singleDate,
    noDates,
  } = props;
  const [filterState, setFilterState] = useState(defaultState);

  //Handlers
  const handleApply = (e) => {
    e.stopPropagation();
    toggleModal(true);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    toggleModal(false);
  };

  const handleReset = (e) => {
    resetPage();
    toggleModal(false);
    reset();
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "DatePickerEndDate": {
        const startDate = moment(filterState.startDate).toDate();
        const endDate = moment(e.target.value).toDate();

        if (startDate > endDate) {
          setFilterState((prev) => ({
            ...prev,
            startDate: e.target.value,
            endDate: e.target.value,
            applyButtonIsDisable: false,
          }));

          const event = new Event("pickerStartDateEvent");
          document.dispatchEvent(event);
          event.target.name = "DatePickerStartDate";
          event.target.value = e.target.value;
          startDateDataOnChange(event);
          endDateDataOnChange(e);
        } else {
          setFilterState((prev) => ({
            ...prev,
            endDate: e.target.value,
            applyButtonIsDisable: false,
          }));

          endDateDataOnChange(e);
        }

        break;
      }
      case "DatePickerStartDate": {
        const startDate = moment(e.target.value).toDate();
        const endDate = moment(filterState.endDate).toDate();

        if (singleDate) {
          setFilterState((prev) => ({
            ...prev,
            startDate: e.target.value,
            endDate: e.target.value,
            applyButtonIsDisable: false,
          }));

          startDateDataOnChange(e);
        } else if (startDate > endDate) {
          setFilterState((prev) => ({
            ...prev,
            startDate: e.target.value,
            endDate: e.target.value,
            applyButtonIsDisable: false,
          }));

          const event = new Event("pickerEndDateEvent");
          document.dispatchEvent(event);
          event.target.name = "DatePickerEndDate";
          event.target.value = e.target.value;
          startDateDataOnChange(e);
          endDateDataOnChange(event);
        } else {
          setFilterState((prev) => ({
            ...prev,
            startDate: e.target.value,
            applyButtonIsDisable: false,
          }));

          startDateDataOnChange(e);
        }
        break;
      }
      case "SelectMenu1":
        setFilterState((prev) => ({
          ...prev,
          selectMenu1Value: e.target.value,
          applyButtonIsDisable: false,
        }));
        selectMenu1DataOnChange(e);
        break;
      case "SelectMenu2":
        setFilterState((prev) => ({
          ...prev,
          selectMenu2Value: e.target.value,
          applyButtonIsDisable: false,
        }));
        selectMenu2DataOnChange(e);
        break;
      default:
        break;
    }
  };

  // Methods
  const resetPage = (shouldUpdateResults = false) => {
    setFilterState(defaultState);
  };

  // Section
  const DatePickerSection = () => {
    return (
      <Container fluid className="mb-3 p-0">
        <Row>
          <Col>
            <span style={styles.pickerTitle}>
              {singleDate ? "Date" : "Start Date"}:
            </span>
          </Col>
          <Col>
            <DatePicker
              ref={startDateRef}
              id="DatePickerStartDate"
              name="DatePickerStartDate"
              date={filterState.startDate}
              onChange={handleChange}
            />
          </Col>
        </Row>

        {singleDate || noDates ? null : (
          <>
            <Row style={{ marginTop: 20 }}>
              <Col>
                <span style={styles.pickerTitle}>End Date:</span>
              </Col>
              <Col>
                <DatePicker
                  ref={endDateRef}
                  id="DatePickerEndDate"
                  name="DatePickerEndDate"
                  date={filterState.endDate}
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  };

  const SelectMenu1 = () => {
    const options = selectMenu1Data.map((item) => {
      return (
        <option key={item.id} value={item.value}>
          {item.name}
        </option>
      );
    });

    return (
      <div style={styles.selectMenus}>
        <Row>
          <span style={styles.pickerTitle}>{selectMenu1Title}:</span>
        </Row>
        <select
          className="mb-2 p-2 w-100"
          onChange={handleChange}
          value={filterState.selectMenu1Value}
          name="SelectMenu1"
          id="SelectMenu1"
        >
          <option value="default">Select a {selectMenu1Title}</option>
          {options}
        </select>
      </div>
    );
  };

  const SelectMenu2 = () => {
    const options = selectMenu2Data.map((item) => {
      return (
        <option key={item.id} value={item.value}>
          {item.name}
        </option>
      );
    });

    return (
      <div style={styles.selectMenus}>
        <Row>
          <span style={styles.pickerTitle}>{selectMenu2Title}:</span>
        </Row>
        <select
          className="mb-2 p-2 w-100"
          onChange={handleChange}
          value={filterState.selectMenu2Value}
          name="SelectMenu2"
          id="SelectMenu2"
        >
          <option value="default">Select a {selectMenu2Title}</option>
          {options}
        </select>
      </div>
    );
  };

  return (
    <Modal show={show}>
      <Modal.Body>
        {noDates ? null : <DatePickerSection />}
        <SelectMenu1 />
        {selectMenu2Data !== undefined && <SelectMenu2 />}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white mr-4"
          onClick={(e) => handleApply(e)}
          disabled={filterState.applyButtonIsDisable}
        >
          Apply
        </Button>
        {reset !== undefined && (
          <Button
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white mr-4"
            onClick={(e) => handleReset(e)}
          >
            Reset
          </Button>
        )}
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={(e) => handleCancel(e)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModal;

const styles = {
  pickerTitle: {
    fontWeight: "bold",
    textAlign: "left",
  },
  selectMenus: {
    // marginTop: 20,
  },
};

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDmduaIQQ-O1i5sHqnFVN8xdiHrYlEh1Lk",
  authDomain: "client-cms-26945.firebaseapp.com",
  projectId: "client-cms-26945",
  storageBucket: "client-cms-26945.appspot.com",
  messagingSenderId: "752491516352",
  appId: "1:752491516352:web:fdcebb7669f39aae2f3825",
  measurementId: "G-8W4H3892L7"
};

export const app = initializeApp(firebaseConfig);
import React, { useState } from "react";
import { Button, Form, Modal, Row, Col, Container } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import DatePicker from "../../../../../components/DatePicker";
import { postEditInquiry } from "../../../../../api/vgu/api";
import { formatDateStringAPI } from "../../../../../lib/utils";

const AddEditInquiryModal = (props) => {
  const defaultState = {
    inquiry: {},
  };

  const statuses = [
    "pending",
    "contacted",
    "ongoing",
    "invoiced",
    "deposit paid",
    "cvds paid",
    "trip completed",
    "closed",
  ];

  const editMode =
    typeof props.editMode != "undefined" ? props.editMode : false;
  const title = editMode ? "EDIT INQUIRY" : "ADD INQUIRY";
  const { show, inquiry, toggleModal } = props;
  const [saveButtonIsDisable, setSaveButtonIsDisable] = useState(true);
  const [addState, setAddState] = useState(defaultState);

  // Methods
  const resetPage = (shouldUpdateResults = false) => {
    setAddState(defaultState);
    setSaveButtonIsDisable(true);
    toggleModal(shouldUpdateResults);
  };

  // Handlers
  const handleChange = (e) => {
    switch (e.target.name) {
      default:
        setAddState((prev) => ({
          ...prev,
          inquiry: {
            ...prev.inquiry,
            [e.target.name]: e.target.value,
          },
        }));
        setSaveButtonIsDisable(false);
    }
  };

  const handleCancel = (e) => {
    resetPage();
  };

  const handleSave = (e) => {
    e.stopPropagation();

    const formattedPaidDate =
      addState.inquiry.paidDate !== null &&
      formatDateStringAPI(addState.inquiry.paidDate);

    const formattedServicesPaidDate =
      addState.inquiry.servicesPaidDate !== null &&
      formatDateStringAPI(addState.inquiry.servicesPaidDate);
    const dataBody = {
      inquiryid: inquiry.id,
      status: addState.inquiry.status ?? inquiry.status,
      paidamount: parseInt(addState.inquiry.paidAmount ?? inquiry.paidAmount),
      paiddate: formattedPaidDate,
      servicespaiddate: formattedServicesPaidDate,
      notes: addState.inquiry.notes ?? inquiry.notes,
    };

    if (editMode) {
      postEditInquiry(dataBody, () => {
        resetPage(true);
      });
    }
  };

  // Selection
  const StatusSelectMenu = () => {
    const options = statuses.map((status) => {
      return (
        <option key={status} value={status}>
          {status}
        </option>
      );
    });

    const Cols = () => {
      return (
        <>
          <Col xs="auto" className="pt-1" style={styles.labelCol}>
            <span style={isBrowser ? styles.pickerTitle : styles.labelMobile}>
              Status:
            </span>
          </Col>
          <Col xs="auto">
            <select
              className="mb-2 p-2 w-100"
              onChange={handleChange}
              value={addState.inquiry.status ?? inquiry.status}
              name="status"
              id="status"
            >
              <option value="default">Select a Status</option>
              {options}
            </select>
          </Col>
        </>
      );
    };

    return (
      <>
        <BrowserView>
          <Row className="mb-2">
            <Cols />
          </Row>
        </BrowserView>
        <MobileView>
          <Row className="mb-2 d-flex justify-content-between">
            <Cols />
          </Row>
        </MobileView>
      </>
    );
  };

  return (
    <Modal show={show} size="lg">
      <Modal.Body>
        <Form style={styles.form}>
          <Form.Group>
            <Row className="mb-4">
              <h1 style={styles.pageTitle}>{title}</h1>
            </Row>
          </Form.Group>
          <Form.Group>
            <Container>
              <BrowserView>
                <Row className="mb-2">
                  <Col md="auto" style={styles.labelCol}>
                    <Form.Label>Client Name</Form.Label>
                  </Col>
                  <Col>{inquiry.clientName}</Col>
                  <Col md="auto" style={styles.labelCol}>
                    <Form.Label>Trip Period</Form.Label>
                  </Col>
                  <Col>{inquiry.tripPeriod}</Col>
                </Row>
                <Row className="mb-2">
                  <Col md="auto" style={styles.labelCol}>
                    <Form.Label>Phone</Form.Label>
                  </Col>
                  <Col>{inquiry.phone}</Col>
                  <Col md="auto" style={styles.labelCol}>
                    <Form.Label>Email</Form.Label>
                  </Col>
                  <Col>{inquiry.email}</Col>
                </Row>
                <Row className="mb-2">
                  <Col md="auto" style={styles.labelCol}>
                    <Form.Label>Party Size</Form.Label>
                  </Col>
                  <Col>{`Female(s): ${inquiry.femaleCount}  -  Male(s): ${inquiry.maleCount}`}</Col>
                  <Col md="auto" style={styles.labelCol}>
                    <Form.Label>Source</Form.Label>
                  </Col>
                  <Col>{inquiry.source}</Col>
                </Row>
                <Row className="mb-2">
                  <Col md="auto" style={styles.labelCol}>
                    <Form.Label>Min. Spend</Form.Label>
                  </Col>
                  <Col>
                    <Row>
                      <NumericFormat
                        value={inquiry.minSpendUSD}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        renderText={(formattedValue) => (
                          <span> {formattedValue}</span>
                        )}
                      />
                    </Row>
                    <Row>
                      {inquiry.minSpendGBP > 0 && (
                        <NumericFormat
                          value={inquiry.minSpendGBP}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"£"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={(formattedValue) => (
                            <span>{formattedValue}</span>
                          )}
                        />
                      )}
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="auto" style={styles.labelCol}>
                    <Form.Label>Music Perf.</Form.Label>
                  </Col>
                  <Col>{inquiry.musicPreference}</Col>
                </Row>
                <Row className="mb-2">
                  <Col md="auto" style={styles.labelCol}>
                    <Form.Label>Comments</Form.Label>
                  </Col>
                  <Col>{inquiry.comment}</Col>
                </Row>
                <StatusSelectMenu />
                <Row className="mb-2">
                  <Col md="auto" style={styles.labelCol}>
                    <Form.Label>Deposit Paid</Form.Label>
                  </Col>
                  <Col>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="paidAmount"
                      name="paidAmount"
                      type="text"
                      placeholder="0"
                      defaultValue={inquiry.paidAmount}
                      onChange={handleChange}
                      style={styles.textInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="auto" style={styles.labelCol}>
                    <Form.Label>Paid Date</Form.Label>
                  </Col>
                  <Col>
                    <DatePicker
                      id="datePickerPaidDate"
                      name="paidDate"
                      date={
                        inquiry.paidDate === null ||
                        inquiry.paidDate === "0000-00-00 00:00:00"
                          ? null
                          : new Date(inquiry.paidDate)
                      }
                      onChange={handleChange}
                      width={240}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="auto" style={styles.labelCol}>
                    <Form.Label>CVDS Paid Date</Form.Label>
                  </Col>
                  <Col style={{ paddingTop: 10 }}>
                    <DatePicker
                      id="datePickerServicesPaidDate"
                      name="servicesPaidDate"
                      date={
                        inquiry.servicesPaidDate === null ||
                        inquiry.servicesPaidDate === "0000-00-00 00:00:00"
                          ? null
                          : new Date(inquiry.servicesPaidDate)
                      }
                      onChange={handleChange}
                      width={240}
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col md="auto" style={styles.labelCol}>
                    <Form.Label>Notes</Form.Label>
                  </Col>
                  <Col>
                    <textarea
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="notes"
                      name="notes"
                      type="textarea"
                      placeholder="Notes"
                      rows="5"
                      defaultValue={inquiry.notes}
                      onChange={handleChange}
                      style={styles.textArea}
                    />
                  </Col>
                </Row>
              </BrowserView>
              <MobileView>
                <Row>
                  <Form.Label style={styles.labelMobile}>
                    Client Name:
                  </Form.Label>
                </Row>
                <Row className="mb-2">
                  <span>{inquiry.clientName}</span>
                </Row>
                <Row>
                  <Form.Label style={styles.labelMobile}>
                    Trip Period:
                  </Form.Label>
                </Row>
                <Row className="mb-2">
                  <span>{inquiry.tripPeriod}</span>
                </Row>
                <Row>
                  <Form.Label style={styles.labelMobile}>Phone:</Form.Label>
                </Row>
                <Row className="mb-2">
                  <span>{inquiry.phone}</span>
                </Row>
                <Row>
                  <Form.Label style={styles.labelMobile}>Email:</Form.Label>
                </Row>
                <Row className="mb-2">
                  <span>{inquiry.email}</span>
                </Row>
                <Row>
                  <Form.Label style={styles.labelMobile}>
                    Party Size:
                  </Form.Label>
                </Row>
                <Row className="mb-2">
                  <span>{`Female(s): ${inquiry.femaleCount}  -  Male(s): ${inquiry.maleCount}`}</span>
                </Row>
                <Row>
                  <Form.Label style={styles.labelMobile}>
                    Minimum Spend:
                  </Form.Label>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <NumericFormat
                      value={inquiry.minSpendUSD}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      renderText={(formattedValue) => (
                        <span> {formattedValue}</span>
                      )}
                    />
                  </Col>
                  <Col>
                    {inquiry.minSpendGBP > 0 && (
                      <NumericFormat
                        value={inquiry.minSpendGBP}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"£"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        renderText={(formattedValue) => (
                          <span>{formattedValue}</span>
                        )}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Form.Label style={styles.labelMobile}>
                    Music Perfererance:
                  </Form.Label>
                </Row>
                <Row className="mb-2">
                  <span>{inquiry.musicPreference}</span>
                </Row>
                <Row>
                  <Form.Label style={styles.labelMobile}>Comments:</Form.Label>
                </Row>
                <Row className="mb-2">
                  <span>{inquiry.comment}</span>
                </Row>
                <Row>
                  <Form.Label style={styles.labelMobile}>Source:</Form.Label>
                </Row>
                <Row className="mb-2">
                  <span>{inquiry.source}</span>
                </Row>
                <StatusSelectMenu />
                <Row className="mt-2 mb-2">
                  <Col style={styles.labelCol}>
                    <Form.Label style={styles.labelMobile}>
                      Deposit Paid:
                    </Form.Label>
                  </Col>
                  <Col xs="auto">
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="paidAmount"
                      name="paidAmount"
                      type="text"
                      placeholder="0"
                      defaultValue={inquiry.paidAmount}
                      onChange={handleChange}
                      style={styles.textInput}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Label style={styles.labelMobile}>
                      Paid Date:
                    </Form.Label>
                  </Col>
                  <Col xs="auto">
                    <DatePicker
                      id="datePickerPaidDate"
                      name="paidDate"
                      date={
                        inquiry.paidDate === null ||
                        inquiry.paidDate === "0000-00-00 00:00:00"
                          ? null
                          : new Date(inquiry.paidDate)
                      }
                      onChange={handleChange}
                      width={240}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Label style={styles.labelMobile}>
                      Services Paid Date:
                    </Form.Label>
                  </Col>
                  <Col xs="auto">
                    <DatePicker
                      id="datePickerServicesPaidDate"
                      name="servicesPaidDate"
                      date={
                        inquiry.servicesPaidDate === null ||
                        inquiry.servicesPaidDate === "0000-00-00 00:00:00"
                          ? null
                          : new Date(inquiry.servicesPaidDate)
                      }
                      onChange={handleChange}
                      width={240}
                    />
                  </Col>
                </Row>
                <Row>
                  <Form.Label style={styles.labelMobile}>Notes:</Form.Label>
                </Row>
                <Row>
                  <textarea
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="notes"
                    name="notes"
                    type="textarea"
                    placeholder="Notes"
                    rows="5"
                    defaultValue={inquiry.notes}
                    onChange={handleChange}
                    style={styles.textArea}
                  />
                </Row>
              </MobileView>
            </Container>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white mr-4"
          onClick={(e) => handleSave(e)}
          disabled={saveButtonIsDisable}
        >
          {editMode ? "Save" : "Add"}
        </Button>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={(e) => handleCancel(e)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditInquiryModal;

const styles = {
  labelCol: {
    width: 120,
    fontSize: 15,
  },
  labelMobile: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 0,
  },
  pageTitle: {
    textAlign: "center",
    color: "#0d6efd",
  },
  textArea: {
    width: "100%",
    resize: "none",
  },
  textInput: {
    width: isBrowser ? 240 : 145,
    marginTop: -50,
  },
};

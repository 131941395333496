import React, { useRef, useState, useEffect } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isBrowser } from "react-device-detect";
import { toast } from "react-toastify";
import AddEditBuyerModal from "./AddEditBuyerModal";
import BuyersAreaInfoModal from "./BuyersAreaInfoModal";
import FilterModal from "../../../../../components/modals/FilterModal";
import { YesNoModal } from "../../../../../components/modals/Modals";
import {
  deleteBuyer,
  deleteBuyerAreaInfo,
  getBuyers,
  postEditBuyerAreaInfo,
} from "../../../../../api/ApiManager";

const locations = [
  {
    id: 1,
    name: "Alabama",
    country: "USA",
  },
  {
    id: 2,
    name: "Alaska",
    country: "USA",
  },
  {
    id: 3,
    name: "Arizona",
    country: "USA",
  },
  {
    id: 4,
    name: "Arkansas",
    country: "USA",
  },
  {
    id: 5,
    name: "California",
    country: "USA",
  },
  {
    id: 6,
    name: "Colorado",
    country: "USA",
  },
  {
    id: 7,
    name: "Connecticut",
    country: "USA",
  },
  {
    id: 8,
    name: "Delaware",
    country: "USA",
  },
  {
    id: 9,
    name: "Florida",
    country: "USA",
  },
  {
    id: 10,
    name: "Georgia",
    country: "USA",
  },
  {
    id: 11,
    name: "Hawaii",
    country: "USA",
  },
  {
    id: 12,
    name: "Idaho",
    country: "USA",
  },
  {
    id: 13,
    name: "Illinois",
    country: "USA",
  },
  {
    id: 14,
    name: "Indiana",
    country: "USA",
  },
  {
    id: 15,
    name: "Iowa",
    country: "USA",
  },
  {
    id: 16,
    name: "Kansas",
    country: "USA",
  },
  {
    id: 17,
    name: "Kentucky",
    country: "USA",
  },
  {
    id: 18,
    name: "Louisiana",
    country: "USA",
  },
  {
    id: 19,
    name: "Maine",
    country: "USA",
  },
  {
    id: 20,
    name: "Maryland",
    country: "USA",
  },
  {
    id: 21,
    name: "Massachusetts",
    country: "USA",
  },
  {
    id: 22,
    name: "Michigan",
    country: "USA",
  },
  {
    id: 23,
    name: "Minnesota",
    country: "USA",
  },
  {
    id: 24,
    name: "Mississippi",
    country: "USA",
  },
  {
    id: 25,
    name: "Missouri",
    country: "USA",
  },
  {
    id: 26,
    name: "Montana",
    country: "USA",
  },
  {
    id: 27,
    name: "Nebraska",
    country: "USA",
  },
  {
    id: 28,
    name: "Nevada",
    country: "USA",
  },
  {
    id: 29,
    name: "New Hampshire",
    country: "USA",
  },
  {
    id: 30,
    name: "New Jersey",
    country: "USA",
  },
  {
    id: 31,
    name: "New Mexico",
    country: "USA",
  },
  {
    id: 32,
    name: "New York",
    country: "USA",
  },
  {
    id: 33,
    name: "North Carolina",
    country: "USA",
  },
  {
    id: 34,
    name: "North Dakota",
    country: "USA",
  },
  {
    id: 35,
    name: "Ohio",
    country: "USA",
  },
  {
    id: 36,
    name: "Oklahoma",
    country: "USA",
  },
  {
    id: 37,
    name: "Oregon",
    country: "USA",
  },
  {
    id: 38,
    name: "Pennsylvania",
    country: "USA",
  },
  {
    id: 39,
    name: "Rhode Island",
    country: "USA",
  },
  {
    id: 40,
    name: "South Carolina",
    country: "USA",
  },
  {
    id: 41,
    name: "South Dakota",
    country: "USA",
  },
  {
    id: 42,
    name: "Tennessee",
    country: "USA",
  },
  {
    id: 43,
    name: "Texas",
    country: "USA",
  },
  {
    id: 44,
    name: "Utah",
    country: "USA",
  },
  {
    id: 45,
    name: "Vermont",
    country: "USA",
  },
  {
    id: 46,
    name: "Virginia",
    country: "USA",
  },
  {
    id: 47,
    name: "Washington",
    country: "USA",
  },
  {
    id: 48,
    name: "West Virginia",
    country: "USA",
  },
  {
    id: 49,
    name: "Wisconsin",
    country: "USA",
  },
  {
    id: 50,
    name: "Wyoming",
    country: "USA",
  },
  {
    id: 51,
    name: "Washington D.C.",
    country: "USA",
  },
];

const Buyers = () => {
  const pageItemCountRef = useRef(10);
  const sortItemRef = useRef("");

  const [searchParams, setSearchParams] = useSearchParams();
  const defaultState = {
    editMode: false,
    areaInfo: {
      id: 0,
      buyerId: 0,
      locationId: 0,
      locationInfo: "",
      criteria: "",
      notes: "",
    },
    buyer: {
      id: 0,
      name: "",
      companyName: "",
      fullAdresss: "",
      email: "",
      phone: "",
      areaInfo: [],
    },
    filterStatus: "",
    pageItemCount: 50,
    tableEditId: -1,
    subTableEditId: -1,
    statuses: [],
  };

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showAddAreaInfoModal, setShowAddAreaInfoModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteModalAreaInfo, setShowDeleteModalAreaInfo] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [buyersData, setBuyersData] = useState({
    currentPage: parseInt(searchParams.get("page") ?? 1),
    buyers: [],
    pages: 0,
    more: false,
  });
  const [buyersState, setBuyersState] = useState(defaultState);

  // Handlers
  const handleAddBuyer = (e) => {
    e.stopPropagation();
    setBuyersState((prev) => ({
      ...prev,
      editMode: false,
      buyer: {
        name: "",
        companyName: "",
        fullAdresss: "",
        email: "",
        phone: "",
        areaInfo: [],
      },
    }));
    setShowAddEditModal(true);
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "SelectPageItems":
        setBuyersState((prev) => ({
          ...prev,
          pageItemCount: e.target.value,
        }));
        handleViewResults();
        break;
      default:
        break;
    }
  };

  const handleChangeSubTable = (e) => {
    switch (e.target.name) {
      case "locationId":
        const locId = parseInt(e.target.value);
        const location = locations.filter((item) => item.id === locId)[0]?.name;

        setBuyersState((prev) => ({
          ...prev,
          areaInfo: {
            ...prev.areaInfo,
            location: location,
            [e.target.name]: locId,
          },
        }));
        break;
      default:
        setBuyersState((prev) => ({
          ...prev,
          areaInfo: {
            ...prev.areaInfo,
            [e.target.name]: e.target.value,
          },
        }));
        break;
    }
  };

  const handleDelete = () => {
    const params = {
      buyerId: buyersState.buyer.id,
    };

    deleteBuyer(params, () => {
      toggleDeleteModal(true);
      toast.error("Buyer Deleted!");
    });
  };

  const handleDeleteAreaInfo = (shouldUpdateResults = false) => {
    const params = {
      areaInfoId: buyersState.areaInfo.id,
    };

    deleteBuyerAreaInfo(params, () => {
      toggleDeleteModalAreaInfo(shouldUpdateResults);
      toast.error("Area Info Deleted!");
    });
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    const currentPage = selectedPage + 1;
    fetchData(currentPage);
  };

  const handleReset = () => {
    setBuyersState((prev) => ({
      ...prev,
      filterStatus: "",
    }));

    fetchData(1, true);
  };

  const handleRowExpanded = (id) => {
    const updatedBuyers = buyersData.buyers.map((buyer) => {
      if (buyer.id === id) {
        return { ...buyer, expanded: !buyer.expanded };
      }
      return buyer;
    });

    setBuyersData({ ...buyersData, buyers: updatedBuyers });
  };

  const handleSaveBuyerAreaInfo = (e) => {
    e.stopPropagation();

    const dataBody = {
      areainfoid: buyersState.areaInfo.id,
      buyerid: buyersState.areaInfo.buyerId,
      locationid: buyersState.areaInfo.locationId,
      locationinfo: buyersState.areaInfo.locationInfo,
      criteria: buyersState.areaInfo.criteria,
      notes: buyersState.areaInfo.notes,
    };

    postEditBuyerAreaInfo(dataBody, () => {
      toast.error("Area Info Updated!");

      setBuyersState((prev) => ({
        ...prev,
        subTableEditId: -1,
      }));

      updateBuyersData();
    });
  };

  const updateBuyersData = () => {
    const updatedBuyers = buyersData.buyers.map((buyer) => {
      const updatedAreaInfo = buyer.areaInfo.map((info) => {
        if (info.id === buyersState.areaInfo.id) {
          return buyersState.areaInfo;
        }
        return info;
      });

      if (buyer.id === buyersState.areaInfo.buyerId) {
        return { ...buyer, areaInfo: updatedAreaInfo };
      }
      return buyer;
    });

    setBuyersData((prev) => ({
      ...prev,
      buyers: updatedBuyers,
    }));
    setBuyersState((prev) => ({
      ...prev,
      subTableEditId: -1,
    }));
  };

  const handleViewResults = () => {
    fetchData(buyersData.currentPage);
  };

  // Modals
  const toggleAddEditModal = (shouldUpdateResults = false) => {
    setShowAddEditModal(!showAddEditModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  const toggleAddAreaInfoModal = (shouldUpdateResults = false) => {
    setShowAddAreaInfoModal(!showAddAreaInfoModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  const toggleDeleteModal = (shouldUpdateResults = false) => {
    setShowDeleteModal(!showDeleteModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  const toggleDeleteModalAreaInfo = (shouldUpdateResults = false) => {
    setShowDeleteModalAreaInfo(!showDeleteModalAreaInfo);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  const toggleFilterModal = (shouldUpdateResults) => {
    setShowFilterModal(!showFilterModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  // Method
  const fetchData = (currentPage, reset = false) => {
    let params = {
      page: currentPage,
      pageitemcount: pageItemCountRef.current.value,
      sortedfield: sortItemRef.current.value,
    };

    // Update the url's query params
    let queryStrings = `page=${currentPage}`;

    if (!reset) {
      // Filter Status
      if (
        buyersState.filterStatus !== "default" &&
        buyersState.filterStatus !== "" &&
        buyersState.filterStatus !== undefined
      ) {
        params["filterstatus"] = buyersState.filterStatus;
        queryStrings += `&filterstatus=${buyersState.filterStatus}`;
      }
    }

    getBuyers(params, (data) => {
      const buyers = data.buyers.map((buyer) => {
        return {
          ...{ expanded: false },
          ...buyer,
        };
      });

      setBuyersData({
        ...{ currentPage: currentPage, buyers: buyers },
        ...data,
      });
    });

    setSearchParams(queryStrings);
  };

  useEffect(() => {
    fetchData(buyersData.currentPage);
  }, []);

  return (
    <Container fluid>
      <Col className="p-0">
        <Row className="mb-1">
          <Col className="d-flex align-items-end">
            <h1 style={styles.pageTitle}>Buyers</h1>
          </Col>
          <Col
            className="px-4 py-2 d-flex justify-content-between"
            xs="auto"
            style={styles.filterSection}
          >
            <Button style={styles.clearButtonStyle} onClick={handleAddBuyer}>
              <FontAwesomeIcon
                icon={"fa-solid fa-square-plus"}
                size="2x"
                style={styles.icon}
              />
            </Button>
            <Button
              style={styles.clearButtonStyle}
              onClick={(e) => {
                e.stopPropagation();
                setShowFilterModal(true);
              }}
            >
              <FontAwesomeIcon
                icon={"fa-solid fa-filter"}
                size="2x"
                style={styles.icon}
              />
            </Button>
            <select
              ref={pageItemCountRef}
              className="p-2 w-80"
              onChange={handleChange}
              name="SelectPageItems"
              id="SelectPageItems"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="250">250</option>
            </select>
          </Col>
        </Row>
        <Row style={styles.table}>
          {buyersData.buyers.length === 0 ? (
            <Row>
              <Col>
                <p style={{ fontSize: 22, fontWeight: "bold", color: "red" }}>
                  No Data: Change Filter
                </p>
              </Col>
            </Row>
          ) : (
            <React.Fragment>
              {isBrowser ? (
                // Browser Version
                <Col>
                  <Table borderless hover>
                    <thead style={styles.tableHeader}>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Company</th>
                        <th>Contact</th>
                        <th>Acquistions</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {buyersData.buyers.map((buyer, index) => {
                        return (
                          <React.Fragment key={`${buyer.id}-${index}`}>
                            <tr style={{ verticalAlign: "middle" }}>
                              <td>
                                <Button
                                  style={styles.clearButtonStyle}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRowExpanded(buyer.id);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      buyer.expanded
                                        ? "fa-solid fa-caret-down"
                                        : "fa-solid fa-caret-right"
                                    }
                                    size="2x"
                                    style={styles.icon}
                                  />
                                </Button>
                              </td>
                              <td>{buyer.name}</td>
                              <td>{buyer.companyName}</td>
                              <td>
                                <Col className="px-3">
                                  <Row>{buyer.phone}</Row>
                                  <Row>{buyer.email}</Row>
                                  <Row>
                                    <a
                                      href={`${buyer.website}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      style={{ padding: 0 }}
                                    >
                                      {buyer.website}
                                    </a>
                                  </Row>
                                </Col>
                              </td>
                              <td>{buyer.acquistions}</td>
                              <td style={{ textAlign: "center" }}>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setBuyersState((prev) => ({
                                      ...prev,
                                      editMode: true,
                                      buyer: buyer,
                                    }));
                                    setShowAddEditModal(true);
                                  }}
                                  aria-controls="edit-buyer"
                                >
                                  <span style={styles.button}>Edit</span>
                                </Button>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setBuyersState((prev) => ({
                                      ...prev,
                                      editMode: false,
                                      buyer: buyer,
                                    }));
                                    setShowDeleteModal(true);
                                  }}
                                  style={styles.deleteButton}
                                >
                                  <span style={styles.button}>Delete</span>
                                </Button>
                              </td>
                            </tr>
                            {buyer.expanded && (
                              <tr style={styles.subTable}>
                                <td colSpan="7">
                                  <Table
                                    borderless
                                    hover
                                    style={{
                                      textAlign: "left",
                                      paddingBottom: 0,
                                    }}
                                  >
                                    <thead style={styles.tableHeader}>
                                      <tr>
                                        <th>Location</th>
                                        <th>Info</th>
                                        <th>Criteria</th>
                                        <th>Notes</th>
                                        <th></th>
                                        <th
                                          style={{
                                            textAlign: "right",
                                          }}
                                        >
                                          <Button
                                            style={styles.clearButtonStyle}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setBuyersState((prev) => ({
                                                ...prev,
                                                buyer: buyer,
                                              }));
                                              setShowAddAreaInfoModal(true);
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={"fa-solid fa-square-plus"}
                                              size="2x"
                                              style={styles.icon}
                                            />
                                          </Button>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {buyer.areaInfo.map((areaInfo, index) => {
                                        return buyersState.subTableEditId ===
                                          areaInfo.id ? (
                                          <tr
                                            key={`edit-${areaInfo.id}-${index}`}
                                            style={{ backgroundColor: "white" }}
                                          >
                                            <td className="px-2">
                                              <select
                                                className="mb-2 p-2 w-100"
                                                onChange={handleChangeSubTable}
                                                value={
                                                  buyersState.areaInfo
                                                    .locationId
                                                }
                                                name="locationId"
                                                id="locationId"
                                              >
                                                <option value="default">
                                                  Select a Location
                                                </option>
                                                {locations.map((location) => {
                                                  return (
                                                    <option
                                                      key={location.id}
                                                      value={location.id}
                                                    >
                                                      {location.name}
                                                    </option>
                                                  );
                                                })}
                                              </select>
                                            </td>
                                            <td className="px-2">
                                              <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="locationInfo"
                                                name="locationInfo"
                                                type="text"
                                                placeholder="Location Info"
                                                defaultValue={
                                                  buyersState.areaInfo
                                                    .locationInfo
                                                }
                                                onChange={handleChangeSubTable}
                                                style={styles.textInput}
                                              />
                                            </td>
                                            <td className="px-2">
                                              <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="criteria"
                                                name="criteria"
                                                type="text"
                                                placeholder="Criteria"
                                                defaultValue={
                                                  buyersState.areaInfo.criteria
                                                }
                                                onChange={handleChangeSubTable}
                                                style={styles.textInput}
                                              />
                                            </td>
                                            <td className="px-2">
                                              <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="notes"
                                                name="notes"
                                                type="text"
                                                placeholder="Notes"
                                                defaultValue={
                                                  buyersState.areaInfo.notes
                                                }
                                                onChange={handleChangeSubTable}
                                                style={styles.textInput}
                                              />
                                            </td>
                                            <td style={styles.buttonColumn}>
                                              <Button
                                                onClick={
                                                  handleSaveBuyerAreaInfo
                                                }
                                                style={styles.updateButton}
                                              >
                                                <span style={styles.button}>
                                                  Update
                                                </span>
                                              </Button>
                                            </td>
                                            <td style={styles.buttonColumn}>
                                              <Button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setBuyersState((prev) => ({
                                                    ...prev,
                                                    subTableEditId: -1,
                                                  }));
                                                }}
                                              >
                                                <span style={styles.button}>
                                                  Cancel
                                                </span>
                                              </Button>
                                            </td>
                                          </tr>
                                        ) : (
                                          <tr
                                            key={`select-${areaInfo.id}-${index}`}
                                            style={{ backgroundColor: "white" }}
                                          >
                                            <td className="px-2">
                                              {areaInfo.location}
                                            </td>
                                            <td className="px-2">
                                              {areaInfo.locationInfo}
                                            </td>
                                            <td className="px-2">
                                              {areaInfo.criteria}
                                            </td>
                                            <td className="px-2">
                                              {areaInfo.notes}
                                            </td>
                                            <td style={styles.buttonColumn}>
                                              <Button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setBuyersState((prev) => ({
                                                    ...prev,
                                                    subTableEditId: areaInfo.id,
                                                    areaInfo: areaInfo,
                                                  }));
                                                }}
                                                aria-controls="edit-buyer-area-info"
                                              >
                                                <span style={styles.button}>
                                                  Edit
                                                </span>
                                              </Button>
                                            </td>
                                            <td style={styles.buttonColumn}>
                                              <Button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setBuyersState((prev) => ({
                                                    ...prev,
                                                    areaInfo: areaInfo,
                                                  }));
                                                  setShowDeleteModalAreaInfo(
                                                    true
                                                  );
                                                }}
                                                style={styles.deleteButton}
                                              >
                                                <span style={styles.button}>
                                                  Delete
                                                </span>
                                              </Button>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              ) : (
                // Mobile Version
                <Col>
                  {buyersData.buyers.map((buyer) => {
                    return <Col key={buyer.id} md={4} className="pb-3"></Col>;
                  })}
                </Col>
              )}
            </React.Fragment>
          )}
        </Row>
        {buyersData.pages > 1 && (
          <Row className="pt-2 px-2">
            <ReactPaginate
              breakLabel="..."
              nextLabel="→"
              onPageChange={handlePageClick}
              pageCount={buyersData.pages}
              previousLabel="←"
              forcePage={buyersData.currentPage - 1}
              renderOnZeroPageCount={null}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
            />
          </Row>
        )}
        {showAddEditModal && (
          <AddEditBuyerModal
            id="add-edit-buyer-modal"
            show={showAddEditModal}
            toggleModal={toggleAddEditModal}
            buyer={buyersState.buyer}
            editMode={buyersState.editMode}
          />
        )}
        {showAddAreaInfoModal && (
          <BuyersAreaInfoModal
            id="add-buyer-area-info-modal"
            show={showAddAreaInfoModal}
            toggleModal={toggleAddAreaInfoModal}
            buyerId={buyersState.buyer.id}
          />
        )}
        {showFilterModal && (
          <FilterModal
            id="buyer-filter-modal"
            show={showFilterModal}
            reset={handleReset}
            toggleModal={toggleFilterModal}
            selectMenu1Title="Status"
            selectMenu1Data={buyersState.statuses}
            selectMenu1DataOnChange={handleChange}
            noDates={true}
          />
        )}
        {showDeleteModal && (
          <YesNoModal
            show={showDeleteModal}
            toggleModal={toggleDeleteModal}
            title="Delete"
            message={`Are you sure you want to delete ${buyersState.buyer.name}?`}
            yesClick={handleDelete}
          />
        )}
        {showDeleteModalAreaInfo && (
          <YesNoModal
            show={showDeleteModalAreaInfo}
            toggleModal={toggleDeleteModalAreaInfo}
            title="Delete"
            message={`Are you sure you want to delete ${buyersState.areaInfo.location}?`}
            yesClick={handleDeleteAreaInfo}
          />
        )}
      </Col>
    </Container>
  );
};

export default Buyers;

const styles = {
  buttonColumn: {
    textAlign: "center",
    width: 100,
  },
  clearButtonStyle: {
    color: "#0d6efd",
    background: "none",
    border: "none",
    outline: "none",
    padding: 0,
  },
  deleteButton: {
    backgroundColor: "red",
    borderColor: "red",
  },
  filterSection: {
    minWidth: 200,
  },
  icon: {},
  pageTitle: {
    textAlign: "left",
    color: "#0d6efd",
  },
  subTable: {
    backgroundColor: "#0d6efd",
  },
  table: {
    height: "88vh",
    overflow: "scroll",
    textAlign: "left",
  },
  tableHeader: {
    backgroundColor: "#353A40",
    color: "white",
  },
  textInput: {
    width: "100%",
  },
  updateButton: {
    backgroundColor: "green",
    borderColor: "green",
  },
};

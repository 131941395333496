import React, { useState } from "react";
import { Button, Form, Modal, Row, Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import DatePicker from "../../../../../components/DatePicker";
import { formatDateStringAPI } from "../../../../../lib/utils";
import {
  postAddVenueEvent,
  postEditVenueEvent,
} from "../../../../../api/ApiManager";

const AddEditVenueEventModal = (props) => {
  const defaultState = {
    locationId: 0,
    venueId: 0,
    venueEvent: {
      date: null,
    },
  };

  const editMode =
    typeof props.editMode != "undefined" ? props.editMode : false;
  const title = editMode ? "EDIT VENUE EVENTS" : "ADD VENUE EVENTS";
  const { show, event, locations, toggleModal } = props;
  const [saveButtonIsDisable, setSaveButtonIsDisable] = useState(true);
  const [addState, setAddState] = useState(defaultState);

  // Methods
  const resetPage = (shouldUpdateResults = false) => {
    setAddState(defaultState);
    setSaveButtonIsDisable(true);
    toggleModal(shouldUpdateResults);
  };

  // Handlers
  const handleChange = (e) => {
    switch (e.target.name) {
      case "LocationSelectMenu":
        setAddState((prev) => ({
          ...prev,
          locationId: parseInt(e.target.value),
        }));
        break;
      case "VenueSelectMenu":
        setAddState((prev) => ({
          ...prev,
          venueId: parseInt(e.target.value),
        }));
        break;
      default:
        setAddState((prev) => ({
          ...prev,
          venueEvent: {
            ...prev.venueEvent,
            [e.target.name]: e.target.value,
          },
        }));
        setSaveButtonIsDisable(false);
    }
  };

  const handleCancel = (e) => {
    resetPage();
  };

  const handleSave = (e) => {
    e.stopPropagation();

    const formattedDate = formatDateStringAPI(
      addState.venueEvent.date ?? (editMode ? event.date : new Date())
    );

    var dataBody = {
      name: addState.venueEvent.name ?? event.name,
      date: formattedDate,
      genre: addState.venueEvent.genre ?? event.genre,
      venueid: event.venueId,
      locationid:
        addState.locationId !== 0 ? addState.locationId : event.locationId,
    };

    if (editMode) {
      dataBody["venueeventid"] = event.id;
      postEditVenueEvent(dataBody, () => {
        resetPage(true);
        toast.error("Venue Event Edited!");
      });
    } else {
      dataBody["venueid"] = addState.venueId;
      postAddVenueEvent(dataBody, () => {
        resetPage(true);
        toast.error("Venue Event Added!");
      });
    }
  };

  // Section
  const LocationSelectMenu = () => {
    const options = locations.map((location) => {
      return (
        <option key={location.id} value={location.id}>
          {location.name}
        </option>
      );
    });

    return (
      <div style={styles.selectMenus}>
        <Row className="mb-4">
          <Col md="auto" style={styles.labelCol}>
            <span style={styles.pickerTitle}>Location:</span>
          </Col>
          <Col>
            <select
              className="mb-2 p-2 w-100"
              onChange={handleChange}
              value={addState.locationId}
              name="LocationSelectMenu"
              id="LocationSelectMenu"
            >
              <option value="default">Select a Location</option>
              {options}
            </select>
          </Col>
        </Row>
      </div>
    );
  };

  const VenueSelectMenu = () => {
    const location = locations.find((location) => {
      return location.id === addState.locationId;
    });

    let venues = [];
    let disabled = true;

    if (location !== undefined && location.venues.length > 0) {
      venues = location.venues;
      disabled = false;
    }

    const options = venues.map((venue) => {
      return (
        <option key={venue.id} value={venue.id}>
          {venue.name}
        </option>
      );
    });

    return (
      <div style={styles.selectMenus}>
        <Row className="mb-4">
          <Col md="auto" style={styles.labelCol}>
            <span style={styles.pickerTitle}>Venue:</span>
          </Col>
          <Col>
            <select
              disabled={disabled}
              className="mb-2 p-2 w-100"
              onChange={handleChange}
              value={addState.venueId}
              name="VenueSelectMenu"
              id="VenueSelectMenu"
            >
              <option value="default">Select a Venue</option>
              {options}
            </select>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Modal show={show}>
      <Modal.Body>
        <Form style={styles.form}>
          <Form.Group>
            <Row className="mb-4">
              <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-1 text-center">
                {title}
              </h1>
            </Row>
          </Form.Group>
          <Form.Group>
            <Container>
              {editMode ? null : (
                <>
                  <LocationSelectMenu />
                  <VenueSelectMenu />
                </>
              )}
              <Row className="mb-4">
                <Col md="auto" style={styles.labelCol}>
                  <Form.Label>Event Name</Form.Label>
                </Col>
                <Col>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Name"
                    defaultValue={event.name}
                    onChange={handleChange}
                    style={styles.textInput}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md="auto" style={styles.labelCol}>
                  <Form.Label>Genre</Form.Label>
                </Col>
                <Col>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="genre"
                    name="genre"
                    type="text"
                    placeholder="Genre"
                    defaultValue={`${event.genre}`}
                    onChange={handleChange}
                    style={styles.textInput}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md="auto" style={styles.labelCol}>
                  <Form.Label>Date</Form.Label>
                </Col>
                <Col>
                  <DatePicker
                    id="datePickerDate"
                    name="date"
                    date={new Date(event.date)}
                    enableTime={true}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </Container>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white mr-4"
          onClick={(e) => handleSave(e)}
          disabled={saveButtonIsDisable}
        >
          {editMode ? "Save" : "Add"}
        </Button>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={(e) => handleCancel(e)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditVenueEventModal;

const styles = {
  labelCol: { width: 120 },
  textInput: {
    width: "100%",
  },
};

import React, { useRef, useState, useEffect } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";

const RehabCalculator = () => {
  const items = [
    {
      id: 0,
      name: "Roof",
      group: "Exterior",
      lowCost: 2500,
      highCost: 15000,
    },
    {
      id: 0,
      name: "Gutters",
      group: "Exterior",
      lowCost: 500,
      highCost: 5000,
    },
    {
      id: 12,
      name: "Demo (dumpsters)",
      group: "Interior",
      lowCost: 500,
      highCost: 3000,
    },
    {
      id: 13,
      name: "Kitchen",
      group: "Interior",
      lowCost: 5000,
      highCost: 25000,
    },
  ];  

  return (
    <Container>
      <Col className="p-0">
        <Row className="mb-1">
          <Col className="d-flex align-items-end">
            <h1 style={styles.pageTitle}>Rehab Calculator</h1>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default RehabCalculator;

const styles = {
  pageTitle: {
    textAlign: "left",
    color: "#0d6efd",
  },
  textInput: {
    width: "100%",
  },
};

import React from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { setDataActiveTitle } from "../../features/sidebar/sidebarSlice";

export const ProtectedLayout = () => {
  const { token, user } = useAuth();
  const dispatch = useDispatch();

  if (token === null && !user.hasAccess) {
    // console.log("Token ", token, "user", user);
    dispatch(setDataActiveTitle("Dashboard"));
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

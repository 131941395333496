import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import sidebarReducer from "../features/sidebar/sidebarSlice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedSidebarReducer = persistReducer(persistConfig, sidebarReducer);

export const store = configureStore({
  reducer: {
    sidebar: persistedSidebarReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

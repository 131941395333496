import { getUrlSession } from "./UrlSession";
import {
  deleteBuyer,
  deleteBuyerAreaInfo,
  getBuyers,
  getProperties,
  postAddBuyer,
  postAddBuyerAreaInfo,
  postEditBuyer,
  postEditBuyerAreaInfo,
} from "./npp/api";
import {
  deleteVenueEvent,
  deleteVenueSeating,
  getFilterInfo,
  getInquiries,
  getVenueEvents,
  getVenueSeating,
  postAddVenueEvent,
  postAddVenueSeating,
  postDuplicateVenueSeating,
  postEditVenueEvent,
  postEditVenueSeating,
} from "./vgu/api.js";

const BaseApiPath = (qtype) => {
  const API_PATH = "https://cws.crossvisiondevstudios.com/api";
  return `${API_PATH}?qtype=${qtype}`;
};

const getUserInfo = (callback) => {
  const url = `${BaseApiPath("userinfo")}`;
  getUrlSession(url, callback);
};

export {
  deleteBuyer,
  deleteBuyerAreaInfo,
  deleteVenueEvent,
  deleteVenueSeating,
  getBuyers,
  getProperties,
  getFilterInfo,
  getInquiries,
  getUserInfo,
  getVenueEvents,
  getVenueSeating,
  postAddBuyer,
  postAddBuyerAreaInfo,
  postAddVenueEvent,
  postAddVenueSeating,
  postDuplicateVenueSeating,
  postEditBuyer,
  postEditBuyerAreaInfo,
  postEditVenueEvent,
  postEditVenueSeating,
};

import React, { useState } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { postAddBuyer } from "../../../../../api/ApiManager";

const AddEditBuyerModal = (props) => {
  const defaultState = {
    buyer: {
      name: "",
      companyName: "",
      fullAddress: "",
      email: "",
      phone: "",
      website: "",
    },
  };

  const editMode =
    typeof props.editMode != "undefined" ? props.editMode : false;
  const title = editMode ? "EDIT BUYER" : "ADD BUYER";
  const { show, buyer, toggleModal } = props;
  const [saveButtonIsDisable, setSaveButtonIsDisable] = useState(true);
  const [addState, setAddState] = useState(defaultState);

  // Methods
  const resetPage = (shouldUpdateResults = false) => {
    setAddState(defaultState);
    setSaveButtonIsDisable(true);
    toggleModal(shouldUpdateResults);
  };

  // Handlers
  const handleChange = (e) => {
    switch (e.target.name) {
      default:
        setAddState((prev) => ({
          ...prev,
          buyer: {
            ...prev.buyer,
            [e.target.name]: e.target.value,
          },
        }));
        setSaveButtonIsDisable(false);
    }
  };

  const handleCancel = (e) => {
    resetPage();
  };

  const handleSave = (e) => {
    e.stopPropagation();

    const dataBody = {
      name: addState.buyer.name,
      companyname: addState.buyer.companyName,
      fulladdress: addState.buyer.fullAddress,
      email: addState.buyer.email,
      phone: addState.buyer.phone,
      website: addState.buyer.website,
    };

    if (editMode) {
      dataBody["buyerid"] = buyer.id;
    } else {
      postAddBuyer(dataBody, () => {
        resetPage(true);
        toast.error("Buyer Added!");
      });
    }
  };

  return (
    <Modal show={show} size="lg">
      <Modal.Body>
        <Form style={styles.form}>
          <Form.Group>
            <Row className="mb-4">
              <h1 style={styles.pageTitle}>{title}</h1>
            </Row>
          </Form.Group>
          <Form.Group>
            <Row className="mb-4">
              <Col md="auto" style={styles.labelCol}>
                <Form.Label>Name</Form.Label>
              </Col>
              <Col>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Name"
                  defaultValue={buyer.name}
                  onChange={handleChange}
                  style={styles.textInput}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md="auto" style={styles.labelCol}>
                <Form.Label>Company Name</Form.Label>
              </Col>
              <Col>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="companyName"
                  name="companyName"
                  type="text"
                  placeholder="Company Name"
                  defaultValue={buyer.companyName}
                  onChange={handleChange}
                  style={styles.textInput}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md="auto" style={styles.labelCol}>
                <Form.Label>Full Address</Form.Label>
              </Col>
              <Col>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="fullAddress"
                  name="fullAddress"
                  type="text"
                  placeholder="Full Address"
                  defaultValue={buyer.fullAdresss}
                  onChange={handleChange}
                  style={styles.textInput}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md="auto" style={styles.labelCol}>
                <Form.Label>Phone</Form.Label>
              </Col>
              <Col>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="phone"
                  name="phone"
                  type="text"
                  placeholder="Phone"
                  defaultValue={buyer.phone}
                  onChange={handleChange}
                  style={styles.textInput}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md="auto" style={styles.labelCol}>
                <Form.Label>Email</Form.Label>
              </Col>
              <Col>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  name="email"
                  type="text"
                  placeholder="Email"
                  defaultValue={buyer.email}
                  onChange={handleChange}
                  style={styles.textInput}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md="auto" style={styles.labelCol}>
                <Form.Label>Website</Form.Label>
              </Col>
              <Col>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="website"
                  name="website"
                  type="text"
                  placeholder="Website"
                  defaultValue={buyer.website}
                  onChange={handleChange}
                  style={styles.textInput}
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white mr-4"
          onClick={(e) => handleSave(e)}
          disabled={saveButtonIsDisable}
        >
          {editMode ? "Save" : "Add"}
        </Button>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={(e) => handleCancel(e)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditBuyerModal;

const styles = {
  labelCol: { width: 160 },
  pageTitle: {
    textAlign: "center",
    color: "#0d6efd",
  },
  textInput: {
    width: "100%",
  },
};

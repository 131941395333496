import React, { forwardRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { isBrowser } from "react-device-detect";

const DatePicker = forwardRef((props, ref) => {
  const options = {
    mode: "single",
    static: false,
    monthSelectorType: "static",
    dateFormat: props.enableTime === undefined ? "F j, Y" : "F j, Y h:i K",
    defaultDate: [props.date],
    enableTime: props.enableTime ?? false,
    minuteIncrement: 1,
    onReady: (selectedDates, dateStr, instance) => {
      instance.element.value = dateStr;
    },
    onChange: (selectedDates, dateStr, instance) => {
      if (!selectedDates.length) return;

      // Make the event and return ISODate
      instance.element.value = dateStr;
      const event = new Event("pickerEvent");
      instance.element.dispatchEvent(event);
      const ISODate = selectedDates[0].toISOString();
      event.target.name = props.name;
      event.target.value = ISODate;
      props.onChange(event);
    },
  };

  return (
    <Container fluid className="p-0">
      <Row>
        {isBrowser && (
          <Col xs="auto" className="p-0" style={styles.pickerImageContainer}>
            <svg
              className="fill-current text-slate-500 ml-3"
              viewBox="0 0 16 16"
              style={styles.pickerImage}
            >
              <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
            </svg>
          </Col>
        )}
        <Col>
          <Flatpickr
            ref={ref}
            options={options}
            style={{ ...styles.picker, ...{ width: props.width ?? 225 } }}
          />
        </Col>
      </Row>
    </Container>
  );
});

export default DatePicker;

const styles = {
  picker: {
    color: "#000",
    paddingLeft: 35,
    paddingTop: 5,
    paddingBottom: 5,
    width: 225,
  },
  pickerImage: {
    width: 20,
    height: 20,
  },
  pickerImageContainer: {
    position: "absolute",
    marginLeft: 20,
    marginTop: 5,
  },
};

import React from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { SiteRoutes } from "../contants";

const AppNavBar = () => {
  const cvdsIcon = require("../assets/logos/cvdsLogoCropped.png");
  return (
    <Navbar
      className="navbar-nav navbar-dark"
      collapseOnSelect
      expand="lg"
      style={styles.navBar}
    >
      <Container>
        <Navbar.Brand href="/">
          <Image src={cvdsIcon} height="20" width="100" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav navbar-dark">
          <Nav className="ms-auto">
            <Nav.Link
              className="nav-links"
              href={SiteRoutes.dashboard.path}
              style={styles.link}
            >
              Dashboard
            </Nav.Link>
            <Nav.Link
              className="nav-links"
              href={SiteRoutes.serviceRequest.path}
              style={styles.link}
            >
              Service Request
            </Nav.Link>
            <Nav.Link
              className="nav-links"
              href={SiteRoutes.vgu.inquiries.path}
              style={styles.link}
            >
              Inquiries
            </Nav.Link>
            <Nav.Link
              className="nav-links"
              href={SiteRoutes.vgu.venues.path}
              style={styles.link}
            >
              Venues
            </Nav.Link>
            <Nav.Link
              className="nav-links"
              href={SiteRoutes.vgu.venueEvents.path}
              style={styles.link}
            >
              Venue Events
            </Nav.Link>
            <Nav.Link
              className="nav-links"
              href={SiteRoutes.vgu.venueSeating.path}
              style={styles.link}
            >
              Venue Seating
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavBar;

const styles = {
  link: {
    color: "#0d6efd",
    fontWeight: 600,
    fontSize: 16,
    textDecoration: "none",
  },
  navBar: {
    backgroundColor: "#000",
    minHeight: 60,
  },
};

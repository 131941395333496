import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const ProfessionalResources = () => {
  return (
    <Container fluid>
      <Row className="mb-1">
        <Col className="d-flex align-items-end">
          <h1 style={styles.pageTitle}>Professional Resources</h1>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfessionalResources;

const styles = {
  pageTitle: {
    textAlign: "left",
    color: "#0d6efd",
  },
};
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import React from "react";
import { useAuth } from "../../providers/AuthProvider";
import { setDataActiveTitle } from "../../features/sidebar/sidebarSlice";

export const HomeLayout = () => {
  const { token, user } = useAuth();
  const dispatch = useDispatch();

  if (token && user.hasAccess) {
    dispatch(setDataActiveTitle("Dashboard"))
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
};

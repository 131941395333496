import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NumericFormat } from "react-number-format";
import { isBrowser, MobileView } from "react-device-detect";
import { toast } from "react-toastify";
import AddEditVenueSeatingModal from "./AddEditVenueSeatingModal";
import DuplicateVenueSeatingModal from "./DuplicateVenueSeatingModal";
import FilterModal from "../../../../../components/modals/FilterModal";
import { YesNoModal } from "../../../../../components/modals/Modals";
import {
  deleteVenueSeating,
  getFilterInfo,
  getVenueSeating,
} from "../../../../../api/ApiManager";
import { formatDateStringAPI } from "../../../../../lib/utils";

const VenueSeating = () => {
  const defaultState = {
    editMode: false,
    event: {
      id: 0,
      name: "",
      date: "",
      time: "",
      imageUrl: "",
    },
    eventDate: new Date(),
    locationId: 0,
    locations: [
      {
        id: 0,
        name: "",
        venues: [],
      },
    ],
    venueId: 0,
    venueTable: {
      id: 0,
      name: "",
      maxGuestFemales: 0,
      maxGuestMales: 0,
      minPrice: 0,
      tableDescription: "",
    },
    viewDataButtonIsDisable: true,
  };

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [venueTables, setVenueTables] = useState([]);
  const [venueSeatingState, setVenueSeatingState] = useState(defaultState);

  // Filter Select Data
  const locationSelectData = venueSeatingState.locations.map((location) => {
    return {
      id: location.id,
      name: location.name,
      value: location.id,
    };
  });

  const venueSelectData = () => {
    const location = venueSeatingState.locations.find((location) => {
      return location.id === venueSeatingState.locationId;
    });

    let venues = [];

    if (location !== undefined && location.venues.length > 0) {
      venues = location.venues.map((venue) => {
        return {
          id: venue.id,
          name: venue.name,
          value: venue.id,
        };
      });
    }

    return venues;
  };

  // Handlers
  const handleAddEvent = (e) => {
    e.stopPropagation();
    setVenueSeatingState((prev) => ({
      ...prev,
      editMode: false,
      venueTable: {
        name: "",
        maxGuestFemales: 0,
        maxGuestMales: 0,
        minPrice: 0,
        tableDescription: "",
      },
    }));
    setShowAddEditModal(true);
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "DatePickerStartDate":
        setVenueSeatingState((prev) => ({
          ...prev,
          eventDate: e.target.value,
        }));
        break;
      case "SelectMenu1":
        setVenueSeatingState((prev) => ({
          ...venueSeatingState,
          locationId: parseInt(e.target.value),
        }));
        break;
      case "SelectMenu2":
        setVenueSeatingState((prev) => ({
          ...venueSeatingState,
          venueId: parseInt(e.target.value),
        }));
        break;
      default:
        break;
    }
  };

  const handleDelete = () => {
    const params = {
      venueSeatingId: venueSeatingState.venueTable.id,
    };

    deleteVenueSeating(params, () => {
      toggleDeleteModal(true);
      toast.error("Venue Table Deleted!");
    });
  };

  const handleDuplicateEvent = () => {
    setShowDuplicateModal(true);
  };

  const handleViewResults = () => {
    const params = {
      venueId: venueSeatingState.venueId,
      venueEventDate: formatDateStringAPI(venueSeatingState.eventDate),
    };

    getVenueSeating(params, (data) => {
      setVenueTables(data.tables);
      setVenueSeatingState((prev) => ({
        ...prev,
        event: data.event,
      }));
    });
  };

  // Modals
  const toggleAddEditModal = (shouldUpdateResults = false) => {
    setShowAddEditModal(!showAddEditModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  const toggleDeleteModal = (shouldUpdateResults = false) => {
    setShowDeleteModal(!showDeleteModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  const toggleDuplicateModal = (shouldUpdateResults = false) => {
    setShowDuplicateModal(!showDuplicateModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  const toggleFilterModal = (shouldUpdateResults) => {
    setShowFilterModal(!showFilterModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  // Components
  const DataTable = () => {
    if (venueTables.length === 0) {
      return (
        <Row>
          <Col>
            <p style={{ fontSize: 22, fontWeight: "bold", color: "red" }}>
              No Data: Change Filter
            </p>
          </Col>
        </Row>
      );
    }

    const table_rows = venueTables.map((table) => {
      return (
        <tr key={table.id}>
          <td className="px-6 py-3">{table.name}</td>
          <td className="px-6 py-3">
            <NumericFormat
              value={table.minPrice}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale={true}
              renderText={(formattedValue) => <span> {formattedValue}</span>}
            />
          </td>
          <td className="px-6 py-3">{table.maxGuestFemales}</td>
          <td className="px-6 py-3">{table.maxGuestMales}</td>
          <td className="px-6 py-3" style={{ textAlign: "left" }}>
            {table.tableDescription}
          </td>
          <td className="px-6 py-3">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setVenueSeatingState((prev) => ({
                  ...prev,
                  editMode: true,
                  venueTable: table,
                }));
                setShowAddEditModal(true);
              }}
              aria-controls="add-edit-venue-seating-modal"
            >
              <span style={styles.button}>Edit</span>
            </Button>
          </td>
          <td className="px-6 py-3">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setVenueSeatingState((prev) => ({
                  ...prev,
                  editMode: true,
                  venueTable: table,
                }));
                setShowDeleteModal(true);
              }}
              style={styles.deleteButton}
            >
              <span style={styles.button}>Delete</span>
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Table Name</th>
            <th>Min. Price</th>
            <th>Max Guest Females</th>
            <th>Max Guest Males</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>{table_rows}</tbody>
      </Table>
    );
  };

  const DataTableMobile = () => {
    if (venueTables.length === 0) {
      return (
        <Row>
          <Col>
            <p style={{ fontSize: 22, fontWeight: "bold", color: "red" }}>
              No Data: Change Filter
            </p>
          </Col>
        </Row>
      );
    }

    const cards = venueTables.map((table) => {
      return (
        <Col key={table.id} md={4} className="pb-3">
          <Card>
            <Card.Header>
              <Row>
                <p style={{ fontWeight: "bold", color: "#0d6efd" }}>
                  {table.name}
                </p>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <NumericFormat
                  value={table.minPrice}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  renderText={(formattedValue) => (
                    <p>Min Price: {formattedValue}</p>
                  )}
                />
              </Row>
              <Row>
                <Col>
                  <p>Females: {table.maxGuestFemales}</p>
                </Col>
                <Col>
                  <p>Males: {table.maxGuestMales}</p>
                </Col>
              </Row>
              <Row>
                <p>{table.tableDescription}</p>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setVenueSeatingState((prev) => ({
                        ...prev,
                        editMode: true,
                        venueTable: table,
                      }));
                      setShowAddEditModal(true);
                    }}
                    aria-controls="add-edit-venue-seating-modal"
                  >
                    <span style={styles.button}>Edit</span>
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setVenueSeatingState((prev) => ({
                        ...prev,
                        editMode: true,
                        venueTable: table,
                      }));
                      setShowDeleteModal(true);
                    }}
                    style={styles.deleteButton}
                  >
                    <span style={styles.button}>Delete</span>
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      );
    });

    return <Col>{cards}</Col>;
  };

  // Hooks
  useEffect(() => {
    getFilterInfo((info) => {
      setVenueSeatingState((prev) => ({
        ...prev,
        locations: info.locations,
      }));
    });
  }, []);

  return (
    <Container fluid>
      <Col className="p-0">
        <Row className="mb-3">
          <Col className="d-flex align-items-end">
            <h1 style={styles.pageTitle}>Venue Seating</h1>
          </Col>
          {isBrowser && venueSeatingState.event.id !== 0 && (
            <>
              <Col md="auto" className="pt-3">
                <img
                  src={
                    venueSeatingState.event.imageUrl !== ""
                      ? venueSeatingState.event.imageUrl
                      : require("../../../../../assets/images/club.png")
                  }
                  style={styles.eventImage}
                  alt={venueSeatingState.event.name}
                />
              </Col>
              <Col md="auto" className="pt-3" style={styles.eventInfo}>
                <Row>
                  <span style={{ fontWeight: 700 }}>Event:</span>
                </Row>
                <Row>
                  <span>{venueSeatingState.event.name}</span>
                </Row>
                <Row>
                  <span>{venueSeatingState.event.date}</span>
                </Row>
                <Row>
                  <span>{venueSeatingState.event.time}</span>
                </Row>
              </Col>
            </>
          )}

          <Col className="px-2 py-2" xs="auto" style={styles.filterSection}>
            <Row>
              <Col className="d-flex justify-content-between align-items-end">
                <Button
                  style={styles.clearButtonStyle}
                  onClick={handleAddEvent}
                >
                  <FontAwesomeIcon
                    icon={"fa-solid fa-square-plus"}
                    size="2x"
                    style={styles.icon}
                  />
                </Button>
                <Button
                  style={styles.clearButtonStyle}
                  onClick={handleDuplicateEvent}
                >
                  <FontAwesomeIcon
                    icon={"fa-solid fa-clone"}
                    size="2x"
                    style={styles.icon}
                  />
                </Button>
                <Button
                  style={styles.clearButtonStyle}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFilterModal(true);
                  }}
                >
                  <FontAwesomeIcon
                    icon={"fa-solid fa-filter"}
                    size="2x"
                    style={styles.icon}
                  />
                </Button>
              </Col>
            </Row>
            <Row>
              <span style={{ textAlign: "right", fontWeight: "bold" }}>
                {venueTables.length} tables
              </span>
            </Row>
          </Col>
        </Row>
        <MobileView>
          {venueSeatingState.event.id !== 0 && (
            <>
              <Col md="auto">
                <Image
                  src={
                    venueSeatingState.event.imageUrl !== ""
                      ? venueSeatingState.event.imageUrl
                      : require("../../../../../assets/images/club.png")
                  }
                  style={styles.eventImage}
                  alt={venueSeatingState.event.name}
                />
              </Col>
              <Col md="auto" style={styles.eventInfo}>
                <Row>
                  <span style={{ fontWeight: 700 }}>Event:</span>
                </Row>
                <Row>
                  <span>{venueSeatingState.event.name}</span>
                </Row>
                <Row>
                  <span>
                    {venueSeatingState.event.date} -
                    {venueSeatingState.event.time}
                  </span>
                </Row>
                <Row>
                  <span></span>
                </Row>
              </Col>
            </>
          )}
        </MobileView>
        <Row style={styles.table}>
          {isBrowser ? <DataTable /> : <DataTableMobile />}
        </Row>
        <AddEditVenueSeatingModal
          id="add-edit-venue-seating-modal"
          show={showAddEditModal}
          toggleModal={toggleAddEditModal}
          table={venueSeatingState.venueTable}
          locations={venueSeatingState.locations}
          editMode={venueSeatingState.editMode}
        />
        <DuplicateVenueSeatingModal
          id="duplicate-venue-seating-modal"
          show={showDuplicateModal}
          toggleModal={toggleDuplicateModal}
          table={venueSeatingState.venueTable}
          locations={venueSeatingState.locations}
        />
        <FilterModal
          id="inquiry-filter-modal"
          show={showFilterModal}
          toggleModal={toggleFilterModal}
          startDateDataOnChange={handleChange}
          selectMenu1Title="Location"
          selectMenu1Data={locationSelectData}
          selectMenu1DataOnChange={handleChange}
          selectMenu2Title="Venue"
          selectMenu2Data={venueSelectData()}
          selectMenu2DataOnChange={handleChange}
          singleDate={true}
        />
        <YesNoModal
          show={showDeleteModal}
          toggleModal={toggleDeleteModal}
          title="Delete"
          message={`Are you sure you want to delete ${venueSeatingState.venueTable.name}?`}
          yesClick={handleDelete}
        />
      </Col>
    </Container>
  );
};

export default VenueSeating;

const styles = {
  clearButtonStyle: {
    color: "#0d6efd",
    background: "none",
    border: "none",
    outline: "none",
    padding: 0,
  },
  deleteButton: {
    backgroundColor: "red",
    borderColor: "red",
  },
  button: {
    color: "white",
    fontWeight: "bold",
  },
  eventImage: {
    resizeMode: "contain",
    width: 140,
    height: 140,
  },
  eventInfo: {
    textAlign: "left",
  },
  filterSection: {
    minWidth: 140,
  },
  pageTitle: {
    textAlign: "left",
    color: "#0d6efd",
  },
  picker: {
    color: "#000",
    paddingLeft: 35,
    paddingTop: 5,
    paddingBottom: 5,
  },
  pickerImage: {
    width: 16,
    height: 16,
  },
  pickerImageContainer: {
    display: "flex",
    marginLeft: 10,
    marginTop: -27,
  },
  table: {
    marginTop: 30,
    height: "70vh",
    overflow: "scroll",
  },
  selectMenus: {
    // marginTop: 20,
  },
};

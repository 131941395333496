import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { app } from "../lib/Firebase/app";
import { useLocalStorage } from "../lib/useLocalStorage";
import { authMethods } from "../lib/Firebase/authMethods";
import { getUserInfo } from "../api/ApiManager";

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const auth = getAuth(app);

const AuthProvider = (props) => {
  const defaultUserState = {
    uid: "",
    userName: "",
    displayName: "",
    email: "",
    avatarUrl: "",
    hasAccess: false,
  };

  const [errors, setErrors] = useState([]);
  const [token, setToken] = useLocalStorage("token", null);
  const [user, setUser] = useLocalStorage("user", defaultUserState);

  const handleSignIn = (inputs, callback) => {
    return authMethods.signIn(
      inputs.email,
      inputs.password,
      setErrors,
      (success) => {
        callback(success);
      }
    );
  };

  const handleSignInGoogle = useCallback(
    (callback) => {
      authMethods.signInWithGoogle(setErrors, (success) => {
        callback(success);
      });
    },
    [user]
  );

  const handleSignOut = () => {
    setUser(defaultUserState);
    setToken(null);
    authMethods.signOut(setErrors, () => {});
  };

  const authorizeUser = (userAuth) => {
    if (auth.currentUser !== null) {
      auth.currentUser.getIdToken(true).then(async (idToken) => {
        if (userAuth) {
          // Set token to localStorage
          setToken(idToken);
        }
      });
    } else {
      console.log("***********RESET USER & TOKEN")
      setUser(defaultUserState);
      setToken(null);
    }
  };

  const fetchUser = (callback) => {
    getUserInfo((userInfo, error) => {
      // if (error && error.response.status === 500) {
      //   handleSignOut();
      // }

      if (userInfo.hasAccess !== null && userInfo.hasAccess) {
        // Grab token from local storage and set to state.
        setUser(userInfo);
        callback(true);
      } else {
        // toast.error("Access Denied");
        handleSignOut();
        callback(false);
      }
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userAuth) => {
      authorizeUser(userAuth);
    });

    const unsubscribeToken = onIdTokenChanged(auth, (userAuth) => {
      authorizeUser(userAuth);
    });

    return () => {
      unsubscribe();
      unsubscribeToken();
    };
  }, []);

  useEffect(() => {
    if (token) {
      fetchUser((success) => {});
    }
  }, [token]);

  const value = useMemo(
    () => ({
      handleSignIn,
      handleSignInGoogle,
      handleSignOut,
      token,
      errors,
      user,
    }),
    [user, handleSignInGoogle]
  );

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export default AuthProvider;

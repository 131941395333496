import React, { useState } from "react";
import { Button, Row, Col, Form, Image, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validator from "validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../providers/AuthProvider";

const LandingPage = (props) => {
  const cvdsIcon = require("../assets/logos/cvdsLogo.png");
  const { handleSignIn, handleSignInGoogle } = useAuth();
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = async (e) => {
    let errorMsg = "";

    if (
      validator.isEmpty(inputs.email, { ignore_whitespace: true }) ||
      inputs.email.trim() === "N/A"
    ) {
      errorMsg = "Please enter your username.";
    } else if (!validator.isEmail(inputs.email)) {
      errorMsg = "Please enter an email.";
    } else if (validator.isEmpty(inputs.password)) {
      errorMsg = "Please enter your password.";
    }

    if (errorMsg !== "") {
      toast.error(errorMsg);
    } else {
      e.preventDefault();
      await handleSignIn(inputs, (success) => {
        if (!success) {
          toast.error("Incorrect Username and/or Password...");
        }
      });
      setInputs({ email: "", password: "" });
    }
  };

  const handleLoginGoogle = async (e) => {
    e.preventDefault();
    await handleSignInGoogle((success) => {});
  };

  return (
    <Row style={styles.container}>
      <Col style={styles.mainCol}>
        <Row style={styles.signInContainer}>
          <Col>
            <Row className="mb-3">
              <Image src={cvdsIcon}/>
            </Row>
            <Row>
              <span style={styles.sectionTitle}>Sign-In:</span>
            </Row>
            <Row className="mt-2 mb-2">
              <InputGroup>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Username"
                  aria-label="Username"
                  onChange={onChangeHandler}
                  value={inputs.email}
                  autoComplete="username"
                />
              </InputGroup>
            </Row>
            <Row>
              <InputGroup>
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="Password"
                  aria-label="Password"
                  onChange={onChangeHandler}
                  value={inputs.password}
                  autoComplete="current-password"
                />
              </InputGroup>
            </Row>
            <Row className="mt-2 mb-2" style={styles.buttonContainer}>
              <Button onClick={handleLogin}>
                <span style={styles.button}>LOG IN</span>
              </Button>
            </Row>
            <Row className="mt-4 mb-2">
              <Link
                style={{ textAlign: "center" }}
                to="/"
                onClick={handleLoginGoogle}
              >
                <FontAwesomeIcon
                  icon="fa-brands fa-google"
                  color="#5782EF"
                  size="3x"
                />
              </Link>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LandingPage;

const styles = {
  button: {
    color: "white",
    fontWeight: "bold",
  },
  buttonContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  container: {
    backgroundColor: "#CF3724",
    minHeight: "100vh",
    minWidth: "100vw",
  },
  loginButtonStyle: {},
  mainCol: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sectionTitle: {
    color: "#FFF",
    fontWeight: "bold",
  },
  signInContainer: {
    backgroundColor: "#222529",
    width: 300,
    padding: 10,
    borderRadius: "5%",
  },
};

import React, { useRef, useState, useEffect } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isBrowser } from "react-device-detect";
import FilterModal from "../../../../../components/modals/FilterModal";
import { YesNoModal } from "../../../../../components/modals/Modals";
import { getProperties } from "../../../../../api/ApiManager";
import { MARKER_DIAMETER, Marker } from "../../../../../components/maps/marker";

const Properties = () => {
  const pageItemCountRef = useRef(10);
  const sortItemRef = useRef("");

  const [searchParams, setSearchParams] = useSearchParams();
  const defaultState = {
    editMode: false,
    property: {
      id: 0,
    },
    filterStatus: "",
    pageItemCount: 10,
    statuses: [],
    isListView: true,
  };

  // Map
  const defaultMapProps = {
    center: { lat: 32.5141901, lng: -84.9986961 },
    zoom: 5,
  };

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const [propertiesData, setPropertiesData] = useState({
    currentPage: parseInt(searchParams.get("page") ?? 1),
    properties: [],
    pages: 0,
    more: false,
  });
  const [propertiesState, setPropertiesState] = useState(defaultState);

  // Handlers
  const handleAddProperty = (e) => {
    e.stopPropagation();
    setPropertiesState((prev) => ({
      ...prev,
      editMode: false,
      property: {
        address: "",
      },
    }));
    setShowAddEditModal(true);
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "":
        break;
      default:
        break;
    }
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    const currentPage = selectedPage + 1;
    fetchData(currentPage);
  };

  const handleReset = () => {
    setPropertiesData((prev) => ({
      ...prev,
      filterStatus: "",
    }));

    fetchData(1, true);
  };

  const handleToggleView = () => {
    setPropertiesState((prev) => ({
      ...prev,
      isListView: !prev.isListView,
    }));
  };

  const handleViewResults = () => {
    fetchData(propertiesData.currentPage);
  };

  // Modals
  const toggleAddEditModal = (shouldUpdateResults = false) => {
    setShowAddEditModal(!showAddEditModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  // const toggleDeleteModal = (shouldUpdateResults = false) => {
  //   setShowDeleteModal(!showDeleteModal);

  //   if (shouldUpdateResults) {
  //     handleViewResults();
  //   }
  // };

  const toggleFilterModal = (shouldUpdateResults) => {
    setShowFilterModal(!showFilterModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  // Method
  const fetchData = (currentPage, reset = false) => {
    let params = {
      page: currentPage,
      pageitemcount: pageItemCountRef.current.value,
      sortedfield: sortItemRef.current.value,
    };

    // Update the url's query params
    let queryStrings = `page=${currentPage}`;

    if (!reset) {
      // Filter Status
      if (
        propertiesState.filterStatus !== "default" &&
        propertiesState.filterStatus !== "" &&
        propertiesState.filterStatus !== undefined
      ) {
        params["filterstatus"] = propertiesState.filterStatus;
        queryStrings += `&filterstatus=${propertiesState.filterStatus}`;
      }
    }

    getProperties(params, (data) => {
      setPropertiesData({ ...{ currentPage: currentPage }, ...data });
    });

    setSearchParams(queryStrings);
  };

  useEffect(() => {
    fetchData(propertiesData.currentPage);
  }, []);

  return (
    <Container fluid>
      <Col className="p-0">
        <Row className="mb-1">
          <Col className="d-flex align-items-end">
            <h1 style={styles.pageTitle}>Properties</h1>
          </Col>
          <Col
            className="px-4 py-2 d-flex justify-content-between"
            xs="auto"
            style={styles.filterSection}
          >
            <Button style={styles.clearButtonStyle} onClick={handleAddProperty}>
              <FontAwesomeIcon
                icon={"fa-solid fa-square-plus"}
                size="2x"
                style={styles.icon}
              />
            </Button>
            <Button
              style={styles.clearButtonStyle}
              onClick={(e) => {
                e.stopPropagation();
                setShowFilterModal(true);
              }}
            >
              <FontAwesomeIcon
                icon={"fa-solid fa-filter"}
                size="2x"
                style={styles.icon}
              />
            </Button>
            <select
              ref={pageItemCountRef}
              className="p-2 w-80"
              onChange={handleChange}
              name="SelectPageItems"
              id="SelectPageItems"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end">
          <Col
            className="px-4 py-2 d-flex justify-content-between"
            xs="auto"
            style={{ minWidth: 140 }}
          >
            <Button style={styles.clearButtonStyle} onClick={handleToggleView}>
              <FontAwesomeIcon
                icon={"fa-solid fa-map"}
                size="2x"
                style={
                  propertiesState.isListView
                    ? styles.iconUnselected
                    : styles.icon
                }
              />
            </Button>
            <Button style={styles.clearButtonStyle} onClick={handleToggleView}>
              <FontAwesomeIcon
                icon={"fa-solid fa-list"}
                size="2x"
                style={
                  propertiesState.isListView
                    ? styles.icon
                    : styles.iconUnselected
                }
              />
            </Button>
          </Col>
        </Row>
        {propertiesState.isListView ? (
          <>
            <Row style={styles.table}>
              {propertiesData.properties?.length === 0 ? (
                <Row>
                  <Col>
                    <p
                      style={{ fontSize: 22, fontWeight: "bold", color: "red" }}
                    >
                      No Data: Change Filter
                    </p>
                  </Col>
                </Row>
              ) : (
                <React.Fragment>
                  {isBrowser ? (
                    <Col>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Address</th>
                            <th>Beds</th>
                            <th>Baths</th>
                            <th>Size</th>
                            <th>Type</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {propertiesData.properties.map((property) => {
                            return (
                              <tr key={property.id}>
                                <td className="px-2 py-2">
                                  <Col className="px-3">
                                    <Row>{property.address}</Row>
                                    <Row>
                                      {property.city}, {property.state}{" "}
                                      {property.zip}
                                    </Row>
                                  </Col>
                                </td>
                                <td className="px-2 py-2">{property.beds}</td>
                                <td className="px-2 py-2">{property.baths}</td>
                                <td className="px-2 py-2">
                                  {property.sqft} sq.ft.
                                </td>
                                <td className="px-2 py-2">{property.type}</td>
                                <td className="px-2 py-2">{property.status}</td>
                                <td className="px-2 py-2">
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setPropertiesState((prev) => ({
                                        ...prev,
                                        editMode: true,
                                        property: property,
                                      }));
                                      setShowAddEditModal(true);
                                    }}
                                    aria-controls="add-edit-venue-seating-modal"
                                  >
                                    <span style={styles.button}>Edit</span>
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  ) : (
                    // Mobile Version
                    <Col>
                      {propertiesData.properties.map((buyer) => {
                        return (
                          <Col key={buyer.id} md={4} className="pb-3"></Col>
                        );
                      })}
                    </Col>
                  )}
                </React.Fragment>
              )}
            </Row>
            <Row className="pt-2">
              {propertiesData.pages > 1 && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="→"
                  onPageChange={handlePageClick}
                  pageCount={propertiesData.pages}
                  previousLabel="←"
                  forcePage={propertiesData.currentPage - 1}
                  renderOnZeroPageCount={null}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                />
              )}
            </Row>
          </>
        ) : (
          <Row style={{ height: "100vh", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDmduaIQQ-O1i5sHqnFVN8xdiHrYlEh1Lk",
              }}
              defaultCenter={defaultMapProps.center}
              center={defaultMapProps.center}
              defaultZoom={defaultMapProps.zoom}
              yesIWantToUseGoogleMapApiInternals
              hoverDistance={MARKER_DIAMETER / 2}
              options={{
                clickableIcons: false,
                styles: [
                  {
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [{ visibility: "off" }],
                  },
                ],
              }}
            >
              {propertiesData.properties.map((item, index) => {
                let title = `${item.address}`
                let line2 = `${item.city}, ${item.state} ${item.zip}`
                let line3 = `${item.beds} bed(s) ${item.baths} bath(s) - ${item.sqft} sq.ft.`
                return (
                  <Marker
                    key={item.id}
                    lat={item.lat}
                    lng={item.lng}
                    data={{
                      title: title,
                      line2: line2,
                      line3: line3
                    }}
                  />
                );
              })}
            </GoogleMapReact>
          </Row>
        )}
      </Col>
    </Container>
  );
};

export default Properties;

const styles = {
  clearButtonStyle: {
    color: "#0d6efd",
    background: "none",
    border: "none",
    outline: "none",
    padding: 0,
  },
  deleteButton: {
    backgroundColor: "red",
    borderColor: "red",
  },
  filterSection: {
    minWidth: 200,
  },
  icon: {},
  iconUnselected: { color: "grey" },
  pageTitle: {
    textAlign: "left",
    color: "#0d6efd",
  },
  table: {
    height: "80vh",
    overflow: "scroll",
  },
};

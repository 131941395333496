import { deleteUrlSession, getUrlSession, postUrlSession } from "../UrlSession";

const BaseApiPath = (qtype) => {
  // const API_PATH = "http://localhost/4116/api"
  const API_PATH = "https://cws.crossvisiondevstudios.com/api";
  return `${API_PATH}?qtype=${qtype}&clientcode=vgu`;
};

// Cart
const postCartCalc = (body, callback) => {
  const url =
    "https://vipglobaluk.crossvisiondevstudios.com/services/contentservice.php?qtype=cartcalc";
  postUrlSession(url, body, callback);
};

// Filter Info
const getFilterInfo = (callback) => {
  const url = `${BaseApiPath("filterinfo")}`;
  getUrlSession(url, callback);
};

// Inquiries
const getInquiries = (params, callback) => {
  let url = `${BaseApiPath("inquiries")}&page=${params.page}&pageitemcount=${
    params.pageitemcount
  }&sortedfield=${params.sortedfield}`;

  if (params.filterstatus) {
    url += `&filterstatus=${params.filterstatus}`;
  }

  if (params.startDate && params.endDate) {
    url += `&startdate=${params.startDate}&enddate=${params.endDate}`;
  }

  getUrlSession(url, callback);
};

const postEditInquiry = (body, callback) => {
  const url = `${BaseApiPath("editinquiry")}`;
  postUrlSession(url, body, callback);
};

// Venues
const getVenues = (params, callback) => {
  const url = `${BaseApiPath("venues")}&locationid=${params.locationId}`;
  getUrlSession(url, callback);
};

// Venue Events
const deleteVenueEvent = (params, callback) => {
  const url = `${BaseApiPath("removevenueevent")}&venueeventid=${
    params.venueEventId
  }`;
  deleteUrlSession(url, callback);
};

const deleteVenueSeating = (params, callback) => {
  const url = `${BaseApiPath("removevenueseating")}&venueseatingid=${
    params.venueSeatingId
  }`;
  deleteUrlSession(url, callback);
};

const getVenueEvents = (params, callback) => {
  let url = `${BaseApiPath("venueevents")}&venueid=${params.venueId}`;

  if (params.startDate !== undefined && params.endDate !== undefined) {
    url += `&startdate=${params.startDate}&enddate=${params.endDate}`;
  }

  getUrlSession(url, callback);
};

const postAddVenueEvent = (body, callback) => {
  const url = `${BaseApiPath("addvenueevent")}`;
  postUrlSession(url, body, callback);
};

const postEditVenueEvent = (body, callback) => {
  const url = `${BaseApiPath("editvenueevent")}`;
  postUrlSession(url, body, callback);
};

// Venue Seating
const getVenueSeating = (params, callback) => {
  const url = `${BaseApiPath("venueseating")}&venueid=${
    params.venueId
  }&venueeventdate=${params.venueEventDate}`;
  getUrlSession(url, callback);
};

const postAddVenueSeating = (body, callback) => {
  const url = `${BaseApiPath("addvenueseating")}`;
  postUrlSession(url, body, callback);
};

const postDuplicateVenueSeating = (body, callback) => {
  const url = `${BaseApiPath("duplicatevenueseating")}`;
  postUrlSession(url, body, callback);
};

const postEditVenueSeating = (body, callback) => {
  const url = `${BaseApiPath("editvenueseating")}`;
  postUrlSession(url, body, callback);
};

export {
  deleteVenueEvent,
  deleteVenueSeating,
  getFilterInfo,
  getInquiries,
  getVenues,
  getVenueEvents,
  getVenueSeating,
  postAddVenueEvent,
  postAddVenueSeating,
  postCartCalc,
  postDuplicateVenueSeating,
  postEditInquiry,
  postEditVenueEvent,
  postEditVenueSeating,
};

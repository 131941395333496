import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  GoogleAuthProvider,
} from "firebase/auth";
import { app } from "./app";

const auth = getAuth(app);

export const authMethods = {
  signIn: (email, password, setErrors, callback) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        setErrors([]);
        callback(true);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
        // throw Error("Error signing up with password and email!");
        callback(false);
      });
  },
  signInWithGoogle: (setErrors, callback) => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        setErrors([]);
        callback(true);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
        // throw TypeError("Error signing in with password and email!");
        callback(false);
      });
  },
  signOut: (setErrors, callback) => {
    signOut(auth)
      .then(() => {
        callback();
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
        // throw TypeError("Error signing out!");
      });
  },
};

import { deleteUrlSession, getUrlSession, postUrlSession } from "../UrlSession";

const BaseApiPath = (qtype) => {
  const API_PATH = "https://cws.crossvisiondevstudios.com/api";
  return `${API_PATH}?qtype=${qtype}&clientcode=npp`;
};

// Buyers
const deleteBuyer = (params, callback) => {
  const url = `${BaseApiPath("removebuyer")}&buyerid=${params.buyerId}`;
  deleteUrlSession(url, callback);
};

const deleteBuyerAreaInfo = (params, callback) => {
  const url = `${BaseApiPath("removeareainfo")}&areainfoid=${params.areaInfoId}`;
  deleteUrlSession(url, callback);
};

const getBuyers = (params, callback) => {
  let url = `${BaseApiPath("buyers")}&page=${params.page}&pageitemcount=${
    params.pageitemcount
  }&sortedfield=${params.sortedfield}`;

  if (params.filterstatus) {
    url += `&filterstatus=${params.filterstatus}`;
  }

  getUrlSession(url, callback);
};

const postAddBuyer = (body, callback) => {
  const url = `${BaseApiPath("addbuyer")}`;
  // console.log(url, body);
  postUrlSession(url, body, callback);
};

const postAddBuyerAreaInfo = (body, callback) => {
  const url = `${BaseApiPath("addareainfo")}`;
  postUrlSession(url, body, callback);
};

const postEditBuyer = (body, callback) => {
  const url = `${BaseApiPath("editbuyer")}`;
  postUrlSession(url, body, callback);
};

const postEditBuyerAreaInfo = (body, callback) => {
  const url = `${BaseApiPath("editareainfo")}`;
  postUrlSession(url, body, callback);
};

// Properties
const getProperties = (params, callback) => {
  let url = `${BaseApiPath("properties")}&page=${params.page}&pageitemcount=${
    params.pageitemcount
  }&sortedfield=${params.sortedfield}`;

  if (params.filterstatus) {
    url += `&filterstatus=${params.filterstatus}`;
  }

  getUrlSession(url, callback);
};

export {
  deleteBuyer,
  deleteBuyerAreaInfo,
  getBuyers,
  getProperties,
  postAddBuyer,
  postAddBuyerAreaInfo,
  postEditBuyer,
  postEditBuyerAreaInfo
};

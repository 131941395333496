import React from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { isBrowser, MobileView } from "react-device-detect";

const Contractors = () => {
  const contractors = [
    {
      id: 0,
      businessName: "A.D. Roofing & Siding",
      contactName: "Nelson Torres",
      phone: "706-536-4529",
      email: "adroofingandsiding9@gmail.com",
      trade: "General",
      services: [
        "Asphalt Shingle Roof",
        "Metal Roof",
        "Fascia Boards",
        "Siding",
        "Chimney Repair",
        "Painting",
        "Wooden Fence Repair",
        "Sheet Rock",
        "Decks",
      ],
    },
  ];

  // Components
  const DataTable = () => {
    if (contractors.length === 0) {
      return (
        <Row>
          <Col>
            <p style={{ fontSize: 22, fontWeight: "bold", color: "red" }}>
              No Data: Change Filter
            </p>
          </Col>
        </Row>
      );
    }

    const contractor_rows = contractors.map((contractor) => {
      return (
        <tr key={contractor.id}>
          <td className="px-6 py-3">{contractor.businessName}</td>
          <td className="px-6 py-3">{contractor.contactName}</td>
          <td className="px-6 py-3">
            <Col className="px-3">
              <Row>{contractor.phone}</Row>
              <Row>{contractor.email}</Row>
            </Col>
          </td>
          <td className="px-6 py-3">{contractor.trade}</td>
          <td className="px-6 py-3">
            <Button
              onClick={(e) => {
                e.stopPropagation();
              }}
              // disabled={inquiry.items.length === 0}
            >
              0 Properties
            </Button>
          </td>
          <td className="px-6 py-3">
            <Button
              onClick={(e) => {
                e.stopPropagation();
              }}
              // disabled={inquiry.items.length === 0}
            >
              0 Item(s)
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <Col>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Business Name</th>
              <th>Contact Name</th>
              <th>Contact</th>
              <th>Trade</th>
              <th>Properties</th>
              <th>Services</th>
            </tr>
          </thead>
          <tbody>{contractor_rows}</tbody>
        </Table>
      </Col>
    );
  };

  const DataTableMobile = () => {
    if (contractors.length === 0) {
      return (
        <Row>
          <Col>
            <p style={{ fontSize: 22, fontWeight: "bold", color: "red" }}>
              No Data: Change Filter
            </p>
          </Col>
        </Row>
      );
    }

    return <Col></Col>;
  };

  return (
    <Container fluid>
      <Col className="p-0">
        <Row className="mb-1">
          <Col className="d-flex align-items-end">
            <h1 style={styles.pageTitle}>Contractors</h1>
          </Col>
        </Row>
        <Row style={styles.table}>
          {isBrowser ? <DataTable /> : <DataTableMobile />}
        </Row>
      </Col>
    </Container>
  );
};

export default Contractors;

const styles = {
  pageTitle: {
    textAlign: "left",
    color: "#0d6efd",
  },
  table: {
    height: "80vh",
    overflow: "scroll",
  },
};

import React, { useRef, useState, useEffect } from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isBrowser } from "react-device-detect";
import { getInquiries } from "../../../../../api/ApiManager";
import AddEditInquiryModal from "./AddEditInquiryModal";
import FilterModal from "../../../../../components/modals/FilterModal";
import InquiryItemsModal from "./InquiryItemsModal";

const Inquiries = () => {
  const pageItemCountRef = useRef(10);
  const sortItemRef = useRef("");

  const [searchParams, setSearchParams] = useSearchParams();
  const defaultState = {
    editMode: false,
    inquiry: {
      id: "0",
      clientName: "",
      email: "",
      phone: "",
      tripPeriod: "",
      arrivalDate: null,
      musicPreference: "",
      comment: "",
      notes: "",
      femaleCount: 0,
      maleCount: 0,
      minSpendGBP: 0,
      minSpendUSD: 0,
      paidAmount: 0,
      paidDate: null,
      servicesPaidDate: null,
      status: "",
      items: [],
    },
    filterStatus: "",
    inquiryStartDate: new Date(),
    inquiryEndDate: new Date(),
    pageItemCount: 10,
    statuses: [
      { id: 0, name: "pending", value: "pending" },
      { id: 1, name: "contacted", value: "contacted" },
      { id: 2, name: "ongoing", value: "ongoing" },
      { id: 3, name: "invoiced", value: "invoiced" },
      { id: 4, name: "deposit paid", value: "deposit paid" },
      { id: 5, name: "cvds paid", value: "cvds paid" },
      { id: 6, name: "trip completed", value: "trip completed" },
      { id: 7, name: "closed", value: "closed" },
    ],
  };

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showInquiryItemsModal, setShowInquiryItemsModal] = useState(false);
  const [inquiryData, setInquiryData] = useState({
    currentPage: parseInt(searchParams.get("page") ?? 1),
    inquiries: [],
    pages: 0,
    more: false,
  });
  const [inquiriesState, setInquiriesState] = useState(defaultState);

  // Handlers
  const handleChange = (e) => {
    switch (e.target.name) {
      case "DatePickerEndDate": {
        setInquiriesState((prev) => ({
          ...prev,
          inquiryEndDate: e.target.value,
        }));
        break;
      }
      case "DatePickerStartDate": {
        setInquiriesState((prev) => ({
          ...prev,
          inquiryStartDate: e.target.value,
        }));
        break;
      }
      case "SelectMenu1":
        setInquiriesState((prev) => ({
          ...prev,
          filterStatus: e.target.value,
        }));
        break;
      case "SelectPageItems":
        setInquiriesState((prev) => ({
          ...prev,
          pageItemCount: e.target.value,
        }));
        handleViewResults();
        break;
      case "SelectSortItems":
        handleViewResults();
        break;
      default:
        break;
    }
  };

  const handleReset = () => {
    setInquiriesState((prev) => ({
      ...prev,
      inquiryStartDate: new Date(),
      inquiryEndDate: new Date(),
      filterStatus: "",
    }));

    fetchData(1, true);
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    const currentPage = selectedPage + 1;
    fetchData(currentPage);
  };

  const handleViewResults = () => {
    fetchData(inquiryData.currentPage);
  };

  // Modals
  const toggleAddEditModal = (shouldUpdateResults = false) => {
    setShowAddEditModal(!showAddEditModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  const toggleFilterModal = (shouldUpdateResults) => {
    setShowFilterModal(!showFilterModal);

    if (shouldUpdateResults) {
      handleViewResults();
    }
  };

  const toggleInquiryItemsModal = () => {
    setShowInquiryItemsModal(!showInquiryItemsModal);
  };

  // Method
  const fetchData = (currentPage, reset = false) => {
    let params = {
      page: currentPage,
      pageitemcount: pageItemCountRef.current.value,
      sortedfield: sortItemRef.current.value,
    };

    // Update the url's query params
    let queryStrings = `page=${currentPage}`;

    if (!reset) {
      // Filter Status
      if (
        inquiriesState.filterStatus !== "default" &&
        inquiriesState.filterStatus !== "" &&
        inquiriesState.filterStatus !== undefined
      ) {
        params["filterstatus"] = inquiriesState.filterStatus;
        queryStrings += `&filterstatus=${inquiriesState.filterStatus}`;
      }

      // Start & End Dates
      const startDate = moment(inquiriesState.inquiryStartDate).format(
        "MM/DD/YYYY"
      );
      const endDate = moment(inquiriesState.inquiryEndDate).format(
        "MM/DD/YYYY"
      );
      const currentDate = moment(new Date()).format("MM/DD/YYYY");

      if (startDate !== currentDate || endDate !== currentDate) {
        params["startDate"] = startDate;
        params["endDate"] = endDate;
        queryStrings += `&startdate=${startDate}&enddate=${endDate}`;
      }
    }

    getInquiries(params, (data) => {
      setInquiryData({ ...{ currentPage: currentPage }, ...data });
    });

    setSearchParams(queryStrings);
  };

  // Sections
  const DataTable = () => {
    if (inquiryData.inquiries.length === 0) {
      return (
        <Row>
          <Col>
            <p style={{ fontSize: 22, fontWeight: "bold", color: "red" }}>
              No Data: Change Filter
            </p>
          </Col>
        </Row>
      );
    }

    const table_rows = inquiryData.inquiries.map((inquiry) => {
      return (
        <tr key={inquiry.id}>
          <td className="px-6 py-3">
            <Button
              style={styles.clearButtonStyle}
              onClick={(e) => {
                e.stopPropagation();
                setInquiriesState((prev) => ({
                  ...prev,
                  editMode: true,
                  inquiry: inquiry,
                }));
                setShowAddEditModal(true);
              }}
            >
              {inquiry.clientName}
            </Button>
          </td>
          <td className="px-6 py-3">{inquiry.dateReceived}</td>
          <td className="px-6 py-3">{inquiry.tripPeriod}</td>
          <td className="px-6 py-3">
            <Col className="px-3">
              <Row>{inquiry.phone}</Row>
              <Row>{inquiry.email}</Row>
            </Col>
          </td>
          <td className="px-6 py-3">
            <Col className="px-3">
              <Row>Females: {inquiry.femaleCount}</Row>
              <Row>Males: {inquiry.maleCount}</Row>
            </Col>
          </td>
          <td className="px-6 py-3">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setInquiriesState((prev) => ({
                  ...prev,
                  inquiry: inquiry,
                }));
                setShowInquiryItemsModal(true);
              }}
              disabled={inquiry.items.length === 0}
            >
              {inquiry.items.length} Item(s)
            </Button>
          </td>
          <td className="px-6 py-3">
            <Col>
              <Row>
                <NumericFormat
                  value={inquiry.minSpendUSD}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  renderText={(formattedValue) => (
                    <span> {formattedValue}</span>
                  )}
                />
              </Row>
              <Row>
                {inquiry.minSpendGBP > 0 && (
                  <NumericFormat
                    value={inquiry.minSpendGBP}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"£"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={(formattedValue) => (
                      <span>{formattedValue}</span>
                    )}
                  />
                )}
              </Row>
            </Col>
          </td>
          <td className="px-6 py-3">
            <NumericFormat
              value={inquiry.paidAmount}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale={true}
              renderText={(formattedValue) => <span> {formattedValue}</span>}
            />
          </td>
          <td className="px-6 py-3">{inquiry.status}</td>
        </tr>
      );
    });

    return (
      <Col>
        <Table className="table" striped bordered hover responsive style={styles.table}>
          <thead>
            <tr>
              <th>Client Name</th>
              <th>Received Date</th>
              <th>Trip Period</th>
              <th>Contact Info.</th>
              <th>Party Size</th>
              <th>Items</th>
              <th>Minimum Spends</th>
              <th>Paid Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{table_rows}</tbody>
        </Table>
      </Col>
    );
  };

  const DataTableMobile = () => {
    if (inquiryData.inquiries.length === 0) {
      return (
        <Row>
          <Col>
            <p style={{ fontSize: 22, fontWeight: "bold", color: "red" }}>
              No Data: Change Filter
            </p>
          </Col>
        </Row>
      );
    }

    const cards = inquiryData.inquiries.map((inquiry) => {
      return (
        <Col key={inquiry.id} md={4} className="pb-3">
          <Card>
            <Card.Header>
              <Row>
                <Button
                  style={styles.clearButtonStyle}
                  onClick={(e) => {
                    e.stopPropagation();
                    setInquiriesState((prev) => ({
                      ...prev,
                      editMode: true,
                      inquiry: inquiry,
                    }));
                    setShowAddEditModal(true);
                  }}
                >
                  {inquiry.clientName}
                </Button>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <p>{inquiry.tripPeriod}</p>
              </Row>
              <Row>
                <p>{inquiry.phone}</p>
              </Row>
              <Row>
                <p>{inquiry.email}</p>
              </Row>
              <Row>
                <Col>Females: {inquiry.femaleCount}</Col>
                <Col>Males: {inquiry.maleCount}</Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Min. Spend GBP</th>
                      <th>Min. Spend USD</th>
                      <th>Paid Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <NumericFormat
                          value={inquiry.minSpendGBP}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"£"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={(formattedValue) => (
                            <span>{formattedValue}</span>
                          )}
                        />
                      </td>
                      <td>
                        <NumericFormat
                          value={inquiry.minSpendUSD}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={(formattedValue) => (
                            <span> {formattedValue}</span>
                          )}
                        />
                      </td>
                      <td>
                        <NumericFormat
                          value={inquiry.paidAmount}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={(formattedValue) => (
                            <span> {formattedValue}</span>
                          )}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
              <Row>
                <p style={{ textAlign: "center", fontWeight: "bold" }}>
                  Status: {inquiry.status}
                </p>
              </Row>
              <Row>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setInquiriesState((prev) => ({
                      ...prev,
                      inquiry: inquiry,
                    }));
                    setShowInquiryItemsModal(true);
                  }}
                  disabled={inquiry.items.length === 0}
                >
                  {inquiry.items.length} Item(s)
                </Button>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      );
    });

    return <Col>{cards}</Col>;
  };

  useEffect(() => {
    fetchData(inquiryData.currentPage);
  }, []);

  return (
    <Container fluid>
      <Col className="p-0">
        <Row className="mb-1">
          <Col className="d-flex align-items-end">
            <h1 style={styles.pageTitle}>Inquiries</h1>
          </Col>
          <Col
            className="px-4 py-2 d-flex justify-content-between"
            xs="auto"
            style={styles.filterSection}
          >
            <Button
              style={styles.clearButtonStyle}
              onClick={(e) => {
                e.stopPropagation();
                setShowFilterModal(true);
              }}
            >
              <FontAwesomeIcon
                icon={"fa-solid fa-filter"}
                size="2x"
                style={styles.icon}
              />
            </Button>
            <select
              ref={pageItemCountRef}
              className="p-2 w-80"
              onChange={handleChange}
              name="SelectPageItems"
              id="SelectPageItems"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs="auto" className="pt-2">
            <span>{inquiryData.total} inquiries</span>
          </Col>
          <Col xs="auto" className="pt-2">
            Sort by
            <select
              ref={sortItemRef}
              className="y-2 w-80"
              onChange={handleChange}
              name="SelectSortItems"
              id="SelectSortItems"
              style={{ border: "none" }}
            >
              <option value="newestinquiry">Newest Inquiry</option>
              <option value="oldestinquiry">Oldest Inquiry</option>
              <option value="earliesttrip">Earliest Trip</option>
              <option value="furthesttrip">Furthest Trip</option>
              <option value="client">Client Name</option>
              <option value="status">Status</option>
            </select>
          </Col>
        </Row>
        <Row style={styles.table}>
          {isBrowser ? <DataTable /> : <DataTableMobile />}
        </Row>
        <Row className="pt-2">
          {inquiryData.pages > 1 && (
            <ReactPaginate
              breakLabel="..."
              nextLabel="→"
              onPageChange={handlePageClick}
              pageCount={inquiryData.pages}
              previousLabel="←"
              forcePage={inquiryData.currentPage - 1}
              renderOnZeroPageCount={null}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
            />
          )}
        </Row>
        <AddEditInquiryModal
          id="add-edit-inquiry-modal"
          show={showAddEditModal}
          toggleModal={toggleAddEditModal}
          inquiry={inquiriesState.inquiry}
          editMode={inquiriesState.editMode}
        />
        <FilterModal
          id="inquiry-filter-modal"
          show={showFilterModal}
          reset={handleReset}
          toggleModal={toggleFilterModal}
          startDateDataOnChange={handleChange}
          endDateDataOnChange={handleChange}
          selectMenu1Title="Status"
          selectMenu1Data={inquiriesState.statuses}
          selectMenu1DataOnChange={handleChange}
        />
        <InquiryItemsModal
          id="inquiry-Items-modal"
          show={showInquiryItemsModal}
          toggleModal={toggleInquiryItemsModal}
          inquiry={inquiriesState.inquiry}
        />
      </Col>
    </Container>
  );
};

export default Inquiries;

const styles = {
  button: {
    color: "white",
    fontWeight: "bold",
  },
  clearButtonStyle: {
    color: "#0d6efd",
    background: "none",
    border: "none",
    outline: "none",
    padding: 0,
  },
  filterSection: {
    minWidth: 160,
  },
  icon: {},
  pageTitle: {
    textAlign: "left",
    color: "#0d6efd",
  },
  pickerTitle: {
    fontWeight: "bold",
    textAlign: "left",
  },
  table: {
    height: "80vh",
    overflow: "scroll",
  },
  titleButton: {
    backgroundColor: "transparent",
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    fontFamily: "inherit",
    fontSize: "inherit",
    fontStyle: "inherit",
    fontWeight: "inherit",
    lineHeight: "inherit",
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    marginLeft: 8,
    color: "black",
  },
};

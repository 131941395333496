export const WEB_DOMAIN =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DOMAIN_DEV
    : process.env.REACT_APP_DOMAIN_PROD;

export const API_PATH = (type) => {
  let path = "";
  path = `/${type}`;
  return `${WEB_DOMAIN}${path}`;
};

// Date Time
export const dateTime = (dt) => {
  let date = new Date(dt);
  let formattedDate =
    date.toLocaleDateString() + " " + date.toLocaleTimeString();
  return formattedDate;
};

export const formatDateStringAPI = (dt) => {
  if (dt === undefined) return null;
  
  const date = new Date(dt);
  const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  const localISOTime = new Date(date - tzoffset).toISOString().slice(0, -5).replace("T", " ");
  return localISOTime;
};

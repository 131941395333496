import React, { useState, useLayoutEffect } from "react";
import {
  Button,
  Form,
  Modal,
  Row,
  Col,
  Container,
  Table,
} from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import {
  getVenueEvents,
  getVenueSeating,
  postDuplicateVenueSeating,
} from "../../../../../api/ApiManager";

const DuplicateVenueSeatingModal = (props) => {
  const defaultState = {
    locationId: 0,
    venueId: 0,
    fromVenueEventId: 0,
    toVenueEventId: 0,
    eventDate: undefined,
  };

  const title = "DUPLICATE VENUE SEATING";
  const { show, table, locations, toggleModal } = props;
  const [saveButtonIsDisable, setSaveButtonIsDisable] = useState(true);
  const [addState, setAddState] = useState(defaultState);
  const [venueEvents, setVenueEvents] = useState([]);
  const [venueEventSeating, setVenueEventSeating] = useState([]);

  // Methods
  const resetPage = (shouldUpdateResults = false) => {
    setAddState(defaultState);
    setSaveButtonIsDisable(true);
    toggleModal(shouldUpdateResults);
  };

  // Handlers
  const handleChange = (e) => {
    switch (e.target.name) {
      case "LocationSelectMenu":
        setAddState((prev) => ({
          ...prev,
          locationId: parseInt(e.target.value),
        }));
        break;
      case "VenueSelectMenu":
        setAddState((prev) => ({
          ...prev,
          venueId: parseInt(e.target.value),
        }));
        break;
      case "FromVenueEventSelectMenu":
        setAddState((prev) => ({
          ...prev,
          fromVenueEventId: parseInt(e.target.value),
        }));
        break;
      case "ToVenueEventSelectMenu":
        setAddState((prev) => ({
          ...prev,
          toVenueEventId: parseInt(e.target.value),
        }));
        setSaveButtonIsDisable(false);
        break;
      default:
        break;
    }
  };

  const handleCancel = (e) => {
    resetPage();
  };

  const handleSave = (e) => {
    e.stopPropagation();

    if (addState.fromVenueEventId === 0 || addState.toVenueEventId === 0) {
      toast.error("Select both the From and To Event!");
    } else {
      const dataBody = {
        venueid: addState.venueId !== 0 ? addState.venueId : table.venueId,
        fromvenueeventid: addState.fromVenueEventId,
        tovenueeventid: addState.toVenueEventId,
        eventDate: addState.eventDate,
      };

      postDuplicateVenueSeating(dataBody, () => {
        resetPage(true);
        toast.error("Venue Seating Added!");
      });
    }
  };

  // Sections
  const LocationSelectMenu = () => {
    const options = locations.map((location) => {
      return (
        <option key={location.id} value={location.id}>
          {location.name}
        </option>
      );
    });

    return (
      <div style={styles.selectMenus}>
        <Row className="mb-2">
          <Col xs={12} md={2}>
            <span style={styles.pickerTitle}>Location:</span>
          </Col>
          <Col xs={12} md={10}>
            <select
              className="mb-2 p-2 w-100"
              onChange={handleChange}
              value={addState.locationId}
              name="LocationSelectMenu"
              id="LocationSelectMenu"
            >
              <option value="default">Select a Location</option>
              {options}
            </select>
          </Col>
        </Row>
      </div>
    );
  };

  const VenueSelectMenu = () => {
    const location = locations.find((location) => {
      return location.id === addState.locationId;
    });

    let venues = [];
    let disabled = true;

    if (location !== undefined && location.venues.length > 0) {
      venues = location.venues;
      disabled = false;
    }

    const options = venues.map((venue) => {
      return (
        <option key={venue.id} value={venue.id}>
          {venue.name}
        </option>
      );
    });

    return (
      <div style={styles.selectMenus}>
        <Row className="mb-2">
          <Col xs={12} md={2}>
            <span style={styles.pickerTitle}>Venue:</span>
          </Col>
          <Col xs={12} md={10}>
            <select
              disabled={disabled}
              className="mb-2 p-2 w-100"
              onChange={handleChange}
              value={addState.venueId}
              name="VenueSelectMenu"
              id="VenueSelectMenu"
            >
              <option value="default">Select a Venue</option>
              {options}
            </select>
          </Col>
        </Row>
      </div>
    );
  };

  const VenueEventSelectMenu = () => {
    let disabled = true;

    if (venueEvents.length > 0) {
      disabled = false;
    }

    // const customStyles = {
    //         // ({
    //   //   ...defaultStyles,
    //   //   color: state.data === addState.fromVenueEventId ? "blue" : "yellow",
    //   //   backgroundColor:
    //   //     state.data === addState.fromVenueEventId ? "red" : "orange",
    //   // }),
    // };

    const options = venueEvents.map((event) => {
      let eventDate = moment(event.date).format("MM/DD/YYYY");
      return (
        <option key={event.id} value={event.id}>
          {eventDate}: {event.name} - {event.tablesCount} Tables
        </option>
      );
    });

    return (
      <>
        <div style={styles.selectMenus}>
          <Row className="mb-2">
            <Col xs={12} md={2}>
              <span style={styles.pickerTitle}>From Event:</span>
            </Col>
            <Col xs={12} md={10}>
              <select
                disabled={disabled}
                size={8}
                className="mb-2 p-2 w-100"
                onChange={handleChange}
                value={addState.fromVenueEventId}
                name="FromVenueEventSelectMenu"
                id="FromVenueEventSelectMenu"
                // style={customStyles}
              >
                <option value="0">Select an Event</option>
                {options}
              </select>
            </Col>
          </Row>
        </div>
        <div style={styles.selectMenus}>
          <Row className="mb-2">
            <Col xs={12} md={2}>
              <span style={styles.pickerTitle}>To Event:</span>
            </Col>
            <Col xs={12} md={10}>
              <select
                disabled={disabled}
                size={8}
                className="mb-2 p-2 w-100"
                onChange={handleChange}
                value={addState.toVenueEventId}
                name="ToVenueEventSelectMenu"
                id="ToVenueEventSelectMenu"
              >
                <option value="0">Select an Event</option>
                {options}
              </select>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const SeatingInfo = () => {
    const table_rows = venueEventSeating.map((item) => {
      return (
        <tr key={item.id}>
          <td className="px-6 py-1">{item.name}</td>
          <td className="px-6 py-1">{item.minPrice}</td>
          <td className="px-6 py-1">{item.maxGuestFemales}</td>
          <td className="px-6 py-1">{item.maxGuestMales}</td>
        </tr>
      );
    });

    return (
      <div>
        <Row className="mb-2">
          <span style={styles.pickerTitle}>Tables:</span>
        </Row>
        <Row style={styles.table}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Table Name</th>
                <th>Min. Price</th>
                <th>Max Guest Females</th>
                <th>Max Guest Males</th>
              </tr>
            </thead>
            <tbody style={{ overflowY: "scroll", height: 100 }}>
              {table_rows}
            </tbody>
          </Table>
        </Row>
      </div>
    );
  };

  // Hooks
  useLayoutEffect(() => {
    getVenueEvents({ venueId: addState.venueId }, (data) => {
      setVenueEvents(data);
    });
  }, [addState.venueId]);

  useLayoutEffect(() => {
    const eventDate = venueEvents.filter(
      (item) => item.id === addState.fromVenueEventId
    )[0]?.date;

    getVenueSeating(
      { venueId: addState.venueId, venueEventDate: eventDate },
      (data) => {
        setVenueEventSeating(data.tables);
        setAddState((prev) => ({
          ...prev,
          eventDate: eventDate,
        }));
      }
    );
  }, [addState.venueId, addState.fromVenueEventId, venueEvents]);

  return (
    <Modal show={show} size="xl">
      <Modal.Body>
        <Form style={styles.form}>
          <Form.Group>
            <Row className="mb-2">
              <h1 style={styles.pageTitle}>{title}</h1>
            </Row>
          </Form.Group>
          <Form.Group>
            <Container>
              <LocationSelectMenu />
              <VenueSelectMenu />
              <VenueEventSelectMenu />
              <SeatingInfo />
            </Container>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white mr-4"
          onClick={(e) => handleSave(e)}
          disabled={saveButtonIsDisable}
        >
          Add
        </Button>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={(e) => handleCancel(e)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DuplicateVenueSeatingModal;

const styles = {
  form: {},
  pageTitle: {
    textAlign: "center",
    color: "#0d6efd",
  },
  textInput: {
    width: "100%",
  },
  table: {
    height: 250,
    overflow: "scroll",
  },
  textArea: {
    width: "100%",
    resize: "none",
  },
};

import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isBrowser } from "react-device-detect";
import { getFilterInfo, getVenues } from "../../../../../api/vgu/api";
import FilterModal from "../../../../../components/modals/FilterModal";


const Venues = () => {
  const defaultState = {
    locations: [
      {
        id: 0,
        name: "",
        venues: [],
      },
    ],
    locationId: 0,
  };

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [venues, setVenues] = useState([]);
  const [venuesState, setVenuesState] = useState(defaultState);

  // Filter Select Data
  const locationSelectData = venuesState.locations.map((location) => {
    return {
      id: location.id,
      name: location.name,
      value: location.id,
    };
  });

  // Handlers
  const handleChange = (e) => {
    switch (e.target.name) {
      case "SelectMenu1":
        const value =
          e.target.value !== "default" ? parseInt(e.target.value) : 0;
        setVenuesState((prev) => ({
          ...prev,
          locationId: value,
        }));
        break;
      default:
        break;
    }
  };

  const toggleFilterModal = (shouldUpdateResults) => {
    setShowFilterModal(!showFilterModal);
  };

  // Sections
  const DataTable = () => {
    if (venues !== null && venues.length === 0) {
      return null;
    }

    const table_rows = venues.map((venue) => {
      return (
        <tr key={venue.id}>
          <td className="px-6 py-3">
            <Col md="auto" className="px-3">
              <Row>{venue.name}</Row>
              <Row>{venue.building}</Row>
            </Col>
          </td>
          <td className="px-6 py-3">{venue.type}</td>
          <td className="px-6 py-3">
            <Col className="px-3">
              <Row>{venue.address}</Row>
              <Row>
                {venue.city}, {venue.state} {venue.zip} {venue.country}
              </Row>
            </Col>
          </td>
        </tr>
      );
    });

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Venue Name</th>
            <th>Type</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>{table_rows}</tbody>
      </Table>
    );
  };

  const DataTableMobile = () => {
    if (venues !== null && venues.length === 0) {
      return (
        <Row>
          <Col>
            <p style={{ fontSize: 22, fontWeight: "bold", color: "red" }}>
              No Data: Change Filter
            </p>
          </Col>
        </Row>
      );
    }

    const cards = venues.map((venue) => {
      return (
        <Col key={venue.id} md={4} className="pb-3">
          <Card>
            <Card.Header>
              <Row>
                <p style={{ fontWeight: "bold", color: "#0d6efd" }}>
                  {venue.name}
                </p>
              </Row>
              <Row>
                <p>{venue.building}</p>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <p>{venue.address}</p>
              </Row>
              <Row>
                <p>
                  {venue.city}, {venue.state} {venue.zip} {venue.country}
                </p>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Row>
                <p style={{ fontWeight: "bold" }}>{venue.type}</p>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      );
    });

    return <Col>{cards}</Col>;
  };

  // Hooks
  useEffect(() => {
    getFilterInfo((info) => {
      setVenuesState((prev) => ({
        ...prev,
        locations: info.locations,
      }));
    });
  }, []);

  useEffect(() => {
    const params = {
      locationId: venuesState.locationId,
    };
    getVenues(params, (data) => {
      setVenues(data);
    });
  }, [venuesState.locationId]);

  return (
    <Container fluid>
      <Col className="p-0">
        <Row className="mb-3">
          <Col>
            <h1 style={styles.pageTitle}>Venues</h1>
          </Col>
          <Col className="px-4 py-2" xs="auto" style={styles.filterSection}>
            <Row>
              <Button
                style={styles.clearButtonStyle}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFilterModal(true);
                }}
              >
                <FontAwesomeIcon
                  icon={"fa-solid fa-filter"}
                  size="2x"
                  style={styles.icon}
                />
              </Button>
            </Row>
            <Row>
              <span style={{ textAlign: "right", fontWeight: "bold" }}>
                {venues.length} venues
              </span>
            </Row>
          </Col>
        </Row>
        <Row style={styles.table}>
          {isBrowser ? <DataTable /> : <DataTableMobile />}
        </Row>
        <FilterModal
          id="inquiry-filter-modal"
          show={showFilterModal}
          toggleModal={toggleFilterModal}
          selectMenu1Title="Location"
          selectMenu1Data={locationSelectData}
          selectMenu1DataOnChange={handleChange}
          noDates={true}
        />
      </Col>
    </Container>
  );
};

export default Venues;

const styles = {
  clearButtonStyle: {
    color: "#0d6efd",
    background: "none",
    border: "none",
    outline: "none",
    padding: 0,
  },
  pageTitle: {
    textAlign: "left",
    color: "#0d6efd",
  },
  pickerTitle: {
    textAlign: "left",
  },
  table: {
    marginTop: 30,
    height: "75vh",
    overflow: "scroll",
  },
};

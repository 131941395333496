import React, { useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { postAddVenueSeating } from "../../../../../api/ApiManager";

const AddEditVenueSeatingModal = (props) => {
  const { event, venue, show, toggleModal } = props;
  const defaultState = {
    venueTable: {},
  };

  const [saveButtonIsDisable, setSaveButtonIsDisable] = useState(true);
  const [addState, setAddState] = useState(defaultState);

  // Methods
  const resetPage = (table) => {
    toggleModal(table);
    setAddState(defaultState);
    setSaveButtonIsDisable(true);
  };

  // Handlers
  const handleChange = (e) => {
    switch (e.target.name) {
      default:
        setAddState((prev) => ({
          ...prev,
          venueTable: {
            ...prev.venueTable,
            [e.target.name]: e.target.value,
          },
        }));
        setSaveButtonIsDisable(false);
    }
  };

  const handleCancel = (e) => {
    resetPage();
  };

  const handleSave = (e) => {
    e.stopPropagation();

    const dataBody = {
      venueid: venue.id,
      venueeventid: event.id,
      tablename: addState.venueTable.name,
      minprice: addState.venueTable.minPrice,
      maxguestfemales: parseInt(addState.venueTable.maxGuestFemales),
      maxguestmales: parseInt(addState.venueTable.maxGuestMales),
      description: addState.venueTable.tableDescription,
    };

    postAddVenueSeating(dataBody, (data) => {
      var table = addState.venueTable;
      table.id = data.id;
      resetPage(table);
      toast.error("Venue Seating Added!");
    });
  };

  const eventTitle =
    venue !== null &&
    venue !== undefined &&
    event !== null &&
    event !== undefined
      ? `${venue.name} - ${event.name}`
      : "";

  return (
    <Modal show={show} size="lg">
      <Modal.Body>
        <Form style={styles.form}>
          <Form.Group>
            <Row className="mb-2">
              <h1 style={styles.pageTitle}>Add Venue Seating</h1>
            </Row>
          </Form.Group>
          <Form.Group>
            <Container>
              <Row className="mb-2">
                <h2 style={styles.eventTitle}>{eventTitle}</h2>
              </Row>
              <Row className="mb-2">
                <Col xs={12} md={3}>
                  <Form.Label>Table Name:</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Table Name"
                    onChange={handleChange}
                    style={styles.textInput}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={12} md={3}>
                  <Form.Label>Min. Price:</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="minPrice"
                    name="minPrice"
                    type="text"
                    placeholder="Price"
                    onChange={handleChange}
                    style={styles.textInput}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={12} md={3}>
                  <Form.Label>Female Max Guests:</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="maxGuestFemales"
                    name="maxGuestFemales"
                    type="text"
                    placeholder="Max Guest Females"
                    onChange={handleChange}
                    style={styles.textInput}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={12} md={3}>
                  <Form.Label>Male Max Guest:</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="maxGuestMales"
                    name="maxGuestMales"
                    type="text"
                    placeholder="Max Guests Males"
                    onChange={handleChange}
                    style={styles.textInput}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs={12} md={3}>
                  <Form.Label>Description:</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                  <textarea
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="tableDescription"
                    name="tableDescription"
                    type="textarea"
                    placeholder="Description"
                    rows="5"
                    onChange={handleChange}
                    style={styles.textArea}
                  />
                </Col>
              </Row>
            </Container>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white mr-4"
          onClick={(e) => handleSave(e)}
          disabled={saveButtonIsDisable}
        >
          Add
        </Button>
        <Button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={(e) => handleCancel(e)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditVenueSeatingModal;

const styles = {
  eventTitle: {
    textAlign: "center",
  },
  form: {},
  pageTitle: {
    textAlign: "center",
    color: "#0d6efd",
  },
  textInput: {
    width: "100%",
  },
  textArea: {
    width: "100%",
    resize: "none",
  },
};

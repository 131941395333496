import "./App.css";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { BrowserView, MobileView } from "react-device-detect";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SiteRoutes } from "./contants";
import { useAuth } from "./providers/AuthProvider";

// Layout
import { HomeLayout } from "./components/layouts/HomeLayout";
import { ProtectedLayout } from "./components/layouts/ProtectedLayout";
import AppNavBar from "./components/AppNavBar";
import Sidebar from "./features/sidebar/Sidebar";

// Main
import Dashboard from "./pages/Dashboard";
import LandingPage from "./pages/LandingPage";
import SearchRequest from "./pages/ServiceRequest";

// Client Specific Pages
// ***** NPP
import Buyers from "./pages/data/clients/npp/Buyers/Buyers";
import Contractors from "./pages/data/clients/npp/Contractors/Contractors";
import ProfessionalResources from "./pages/data/clients/npp/ProfessionalResources/ProfessionalResources";
import Properties from "./pages/data/clients/npp/Properties/Properties";
import RehabCalculator from "./pages/data/clients/npp/RehabCalculator/RehabCalculator";

// ***** VGU
import Inquiries from "./pages/data/clients/vgu/Inquiries/Inquiries";
import Venues from "./pages/data/clients/vgu/Venues/Venues";
import VenueEvents from "./pages/data/clients/vgu/VenueEvents/VenueEvents";
import VenueSeating from "./pages/data/clients/vgu/VenueSeating/VenueSeating";

function App() {
  const { token, user } = useAuth();
  const dataActiveTitle = useSelector((state) => state.sidebar.dataActiveTitle);

  return (
    <div className="App">
      <MobileView>
        {token !== null && user.hasAccess && <AppNavBar />}
      </MobileView>
      <Container fluid>
        <Row>
          <Col sm="auto" className="p-0">
            <BrowserView>
              {token !== null && user.hasAccess && (
                <Sidebar activeTitle={dataActiveTitle} />
              )}
            </BrowserView>
          </Col>
          <Col className="p-0">
            <Routes>
              <Route element={<HomeLayout />}>
                <Route path="/" element={<LandingPage />} />
              </Route>
              <Route element={<ProtectedLayout />}>
                <Route
                  index
                  path={SiteRoutes.dashboard.path}
                  element={<Dashboard />}
                />
                <Route
                  path={SiteRoutes.serviceRequest.path}
                  element={<SearchRequest />}
                />
                {/* NPP */}
                <Route path={SiteRoutes.npp.buyers.path} element={<Buyers />} />
                <Route
                  path={SiteRoutes.npp.contractors.path}
                  element={<Contractors />}
                />
                <Route
                  path={SiteRoutes.npp.professionalResources.path}
                  element={<ProfessionalResources />}
                />
                <Route
                  path={SiteRoutes.npp.properties.path}
                  element={<Properties />}
                />
                <Route
                  path={SiteRoutes.npp.rehabCalculator.path}
                  element={<RehabCalculator />}
                />
                {/* VGU */}
                <Route
                  path={SiteRoutes.vgu.inquiries.path}
                  element={<Inquiries />}
                />
                <Route path={SiteRoutes.vgu.venues.path} element={<Venues />} />
                <Route
                  path={SiteRoutes.vgu.venueEvents.path}
                  element={<VenueEvents />}
                />
                <Route
                  path={SiteRoutes.vgu.venueSeating.path}
                  element={<VenueSeating />}
                />
              </Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Col>
        </Row>
        <ToastContainer position="top-center" closeOnClick theme="light" />
      </Container>
    </div>
  );
}

export default App;

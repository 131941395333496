export const SiteRoutes = {
  dashboard: {
    path: "/dashboard",
    title: "Dashboard",
  },
  serviceRequest: {
    path: "/services/request",
    title: "Service Request",
  },
  npp: {
    buyers: {
      path: "/data/npp/buyers",
      title: "Buyers",
    },
    contractors: {
      path: "/data/npp/contractors",
      title: "Contractors",
    },
    professionalResources: {
      path: "/data/npp/professionalResources",
      title: "Professional Resources",
    },
    properties: {
      path: "/data/npp/properties",
      title: "Properties",
    },
    rehabCalculator: {
      path: "/data/npp/rehabCalculator",
      title: "Rehab Calculator",
    }
  },
  vgu: {
    inquiries: {
      path: "/data/vgu/inquiries",
      title: "Inquiries",
    },
    venueEvents: {
      path: "/data/vgu/venueevents",
      title: "Venue Events",
    },
    venues: {
      path: "/data/vgu/venues",
      title: "Venues",
    },
    venueSeating: {
      path: "/data/vgu/venueseating",
      title: "Venue Seating",
    },
  },
};
